import "./style.css";
import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import InputForm from "src/components/Inputs/inputsimple";
import ButtonFormSubmit from "src/components/Inputs/buttonSubmit";
import { axiosApi } from "src/services/axiosInstances";
import { toast, ToastContainer } from "react-toastify";

// type Props =  & {};

type Form = {
  email: string;
};

export default function RecuperarSenha(): JSX.Element {
  const [loader, setloader] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setloader(true);
      await axiosApi.post(`/recuperacao`, form);
      toast.success('Email Enviado!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      window.setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error: any) {
      if (error.response.status === 404) {
        toast.error('Email não encontrado!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      }
    } finally {
      setloader(false);
    }
  };

  const [form, setForm] = useState<Form>({
    email: "",
  });

  const postForm = (event: any) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  return (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      <div className="py-5">
        <div className="px-5">
          <h1 className={`form-title flex py-5 ${window.location.pathname === '/recuperar-senha' ? 'w-screen' : ''}`}>
            <Link title="Voltar" to="/quem-sou">
              <FaAngleLeft className="mr-3" size="40px" />
            </Link>
            Recuperar Senha
          </h1>
          <form onSubmit={handleSubmit}>
            <InputForm
              inputClassName={
                "normal-case px-3 mt-2 border-2 border-gray-400 w-full h-10"
              }
              wrapClassName={"text-center mt-10"}
              name="email"
              label="Digite seu email de cadastro"
              type="email"
              required={true}
              maxlength={50}
              onChange={postForm}
            />
            <ButtonFormSubmit loader={loader} name="btn-submit" type="submit" />
          </form>
        </div>
      </div>
    </Fragment>
  );
}
