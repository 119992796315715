import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
// import Titulo from "../SolicitarCirurgia/Titulo";
import DoutorForm from "./Forms/DoutorForm";
import { connect } from "react-redux";
import { Fornecedores, Usuario } from "src/store/types";
import FornecedorForm from "./Forms/FornecedorForm";
import HospitalForm from "./Forms/HospitalForm";
import AdminForm from "./Forms/AdminForm";
import Loader from "src/components/Loader";
import { Suggestion } from "../SolicitarCirurgia/interfaces";
import { axiosApi } from "src/services/axiosInstances";
import ButtonGerarRelatorio from "./ButtonGerarRelatorio";
import SecretariaForm from "./Forms/SecretariaForm";
import "./style.css";

interface Props {
  usuario: Usuario;
  doutor: Doutor;
}

type Doutor = {
  id: number;
  nome: string;
}

type HomeResponse = {
  doutores: Doutor[];
};

type Option = {
  id: number;
  nome: string;
}

type FornecedorResponse = {
  ativos: Fornecedores[]
  incompletos: Fornecedores[]
};

const RelatorioLayout: React.FC<Props> = ({ usuario, doutor }) => {

  // console.log(usuario.nivel);

  const hospitalCheck = usuario.nivel === 6
  const fornecedorCheck = usuario.nivel === 2;
  const adminCheck = usuario.nivel === 5;
  const doutorCheck = usuario.nivel === 1;
  const vendedorCheck = usuario.nivel === 4;
  const secretariaCheck = usuario.nivel === 3;
  const combinedFornecedorCheck = fornecedorCheck || vendedorCheck;
  const combinedAdminCheck = adminCheck || secretariaCheck;

  const [loader, setLoader] = useState<boolean>(false);
  const [dataInicial, setDataInicial] = useState<string | null>(null);
  const [dataFinal, setDataFinal] = useState<string | null>(null);
  const [descritivo, setDescritivo] = useState<string>('');
  const [hospitalInputValue, setHospitalInputValue] = useState<string>("");
  const [hospitalId, setHospitalId] = useState<number | null>(null);
  const [hospitalSuggestions, setHospitalSuggestions] = useState<Suggestion[]>([]);
  const [participacaoId, setParticipacaoId] = useState<number | null>(null);
  const [doutorSuggestions, setDoutorSuggestions] = useState<Doutor[]>([]);
  const [showHospitalDropdown, setShowHospitalDropdown] = useState<boolean>(false);
  const dropdownHospitalRef = useRef<HTMLUListElement>(null);
  const [participacaoInputValue, setParticipacaoInputValue] = useState<string>("");
  const [showParticipacaoDropdown, setShowParticipacaoDropdown] = useState<boolean>(false);
  const dropdownParticipacaoRef = useRef<HTMLUListElement>(null);
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [doutorId, setDoutorId] = useState<number | null>(null);
  const [doutorInputValue, setDoutorInputValue] = useState("");
  const [showDoutorDropdown, setShowDoutorDropdown] = useState<boolean>(false);
  const [filteredDoutorOptions, setFilteredDoutorOptions] = useState<Doutor[]>([]);
  const doutorDropdownRef = useRef<HTMLUListElement>(null);
  const [fornecedorSuggestions, setFornecedorSuggestions] = useState<Fornecedores[]>([]);
  const [fornecedorInputValue, setFornecedorInputValue] = useState<string>('')
  const [filteredFornecedorOptions, setFilteredFornecedorOptions] = useState<Fornecedores[]>([]);
  const fornecedorDropdownRef = useRef<HTMLUListElement>(null);
  const [showFornecedorDropdown, setShowFornecedorDropdown] = useState<boolean>(false);
  const [fornecedorId, setFornecedorId] = useState<number | null>(null);
  const [doutoresSecretaria, setDoutoresSecretaria] = useState<Doutor[]>([]);



  const fetchHospitais = async (query: string) => {
    try {
      const { data } = await axiosApi.get(`/solicitacao-cirurgia/hospitais/${query}`);
      setHospitalSuggestions(data);
    } catch (error) {
      console.error('Error fetching hospitais', error);
    }
  };

  const fetchFornecedores = async () => {
    try {
      const { data } = await axiosApi.get<FornecedorResponse>(`/list-fornecedores`);
      const combinedFornecedores = [...data.ativos, ...data.incompletos];
      setFornecedorSuggestions(combinedFornecedores);
    }
    catch (error) {
      console.error('Error fetching fornecedores', error);
    }
  }

  const handleHospitalInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setHospitalInputValue(newQuery);
    if (newQuery.length >= 3) {
      fetchHospitais(newQuery);
    } else {
      setHospitalSuggestions([]);
    }
  };

  const handleSuggestionHospitalClick = (suggestion: Suggestion) => {
    setHospitalInputValue(suggestion.nome);
    setHospitalId(suggestion.id);
    setHospitalSuggestions([]);
  };

  const handleDataInicialChange = (event: ChangeEvent<HTMLInputElement>) => {
    const date = new Date(event.target.value);
    const formattedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
    setDataInicial(formattedDate);
  };

  const handleDataFinalChange = (event: ChangeEvent<HTMLInputElement>) => {
    const date = new Date(event.target.value);
    const formattedDate = `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getDate()).padStart(2, '0')}`;
    setDataFinal(formattedDate);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let requestData: any = {};

    if (hospitalCheck) {
      requestData = {
        data_inicial: dataInicial,
        data_final: dataFinal,
        doutor_id: doutorId,
        descricao_cirurgia: descritivo,
        fornecedor_id: fornecedorId,
      };
    } else if (doutorCheck) {
      requestData = {
        data_inicial: dataInicial,
        data_final: dataFinal,
        descricao_cirurgia: descritivo,
        hospital_id: hospitalId,
        fornecedor_id: fornecedorId,
        participacao_id: participacaoId,
      };
    } else if (combinedFornecedorCheck) {
      requestData = {
        data_inicial: dataInicial,
        data_final: dataFinal,
        doutor_id: doutorId,
        descricao_cirurgia: descritivo,
        hospital_id: hospitalId,
      };

    } else if (combinedAdminCheck) {
      requestData = {
        data_inicial: dataInicial,
        data_final: dataFinal,
        doutor_id: doutorId,
        descricao_cirurgia: descritivo,
        hospital_id: hospitalId,
        fornecedor_id: fornecedorId
      };

    }

    try {
      setLoader(true);
      const response = await axiosApi.post('/generateProducaoMedica', requestData);
      const { url } = response.data;
      // console.log('Relatório Enviado', requestData);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Erro ao gerar produção médica:', error);
    } finally {
      setLoader(false);
    }
  };

  const participacaoOptions = [
    { id: 1, nome: "Anestesista" },
    { id: 2, nome: "Auxiliar de Anestesia" },
    { id: 3, nome: "Cirurgião" },
    { id: 4, nome: "Primeiro Auxiliar" },
    { id: 5, nome: "Segundo Auxiliar" },
    { id: 6, nome: "Terceiro Auxiliar" },
    { id: 7, nome: "Outro" },
  ];

  const handleParticipacaoInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setParticipacaoInputValue(newQuery);

    if (newQuery.length > 0) {
      const filtered = participacaoOptions.filter(option =>
        option.nome.toLowerCase().includes(newQuery.toLowerCase())
      );
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(participacaoOptions);
    }

    setShowParticipacaoDropdown(true);
  };

  const handleDoutorInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setDoutorInputValue(newQuery);

    if (newQuery.length > 0) {
      const filtered = doutorSuggestions.filter(option =>
        option.nome.toLowerCase().includes(newQuery.toLowerCase())
      );
      setFilteredDoutorOptions(filtered);
    } else {
      setFilteredDoutorOptions(doutorSuggestions);
    }

    setShowDoutorDropdown(true);
  };

  const handleFornecedorInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setFornecedorInputValue(newQuery);

    if (newQuery.length > 0) {
      const filtered = fornecedorSuggestions.filter(option =>
        option.razao_social.toLowerCase().includes(newQuery.toLowerCase())
      );
      setFilteredFornecedorOptions(filtered);
    } else {
      setFilteredFornecedorOptions(fornecedorSuggestions);
    }

    setShowFornecedorDropdown(true);
  };


  const handleParticipacaoSuggestionClick = (option: { id: number; nome: string }) => {
    setParticipacaoInputValue(option.nome);
    setParticipacaoId(option.id);
    setShowParticipacaoDropdown(false);
  };

  const handleDoutorSuggestionClick = (option: { id: number; nome: string }) => {
    setDoutorInputValue(option.nome);
    setDoutorId(option.id);
    setShowDoutorDropdown(false);
    setFilteredDoutorOptions([]);
  };

  const handleFornecedorSuggestionClick = (option: { id: number; razao_social: string }) => {
    setFornecedorInputValue(option.razao_social);
    setFornecedorId(option.id);
    setShowFornecedorDropdown(false);
    setFilteredFornecedorOptions([]);
  };

  const relatorioProps = {
    handleDataInicialChange,
    handleDataFinalChange,
    setLoader,
    dataInicial,
    setDataInicial,
    dataFinal,
    setDataFinal,
    descritivo,
    setDescritivo,
    hospitalInputValue,
    setHospitalInputValue,
    hospitalId,
    setHospitalId,
    setHospitalSuggestions,
    hospitalSuggestions,
    handleHospitalInputChange,
    handleSuggestionHospitalClick,
    setParticipacaoId,
    setShowHospitalDropdown,
    showHospitalDropdown,
    dropdownHospitalRef,
    handleParticipacaoInputChange,
    participacaoInputValue,
    participacaoOptions,
    handleParticipacaoSuggestionClick,
    setFilteredOptions,
    setShowParticipacaoDropdown,
    showParticipacaoDropdown,
    dropdownParticipacaoRef,
    filteredOptions,
    setParticipacaoInputValue,
    handleDoutorSuggestionClick,
    doutorId,
    doutorInputValue,
    doutorSuggestions,
    handleDoutorInputChange,
    showDoutorDropdown,
    setShowDoutorDropdown,
    doutorDropdownRef,
    filteredDoutorOptions,
    setDoutorInputValue,
    setDoutorSuggestions,
    setDoutorId,
    fornecedorInputValue,
    fornecedorDropdownRef,
    filteredFornecedorOptions,
    showFornecedorDropdown,
    handleFornecedorInputChange,
    setShowFornecedorDropdown,
    handleFornecedorSuggestionClick,
    fornecedorId,
    setFornecedorInputValue,
    setFornecedorId,
    usuario,
    doutoresSecretaria,
  }


  useEffect(() => {
    const loadDados = async () => {
      try {
        const { data } = await axiosApi.get<HomeResponse>(`/listdoctor`);
        setDoutorSuggestions(data.doutores);
      } catch (error: any) {
        console.log(error);
      }
    };
    setDoutoresSecretaria(usuario?.doutores as any)
    loadDados();
    fetchFornecedores();
  }, []);

  return (

    <>
      <Loader
        isActive={loader}
      >
        <div className="p-12 background-mobile relatorio-container p-12-mobile">
          <h2 className="secretariado-title font-black title-mobile">Relatório</h2>
          <form onSubmit={handleSubmit}>
            {doutorCheck &&
              <DoutorForm
                {...relatorioProps}
              />}
            {combinedFornecedorCheck &&
              <FornecedorForm
                {...relatorioProps}
              />}
            {hospitalCheck &&
              <HospitalForm
                {...relatorioProps}
              />}
            {adminCheck &&
              <AdminForm
                {...relatorioProps}
              />}
            {secretariaCheck &&
              <SecretariaForm
                {...relatorioProps} />}
            <ButtonGerarRelatorio
              handleSubmit={handleSubmit}
            />
          </form>
        </div>
      </Loader>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  usuario: state.auth.usuario,
  doutor: state,
});

export default connect(mapStateToProps)(RelatorioLayout);