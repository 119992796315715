import React, { Fragment, useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import InputForm from "src/components/Inputs/inputsimple";
import { axiosApi } from "../../services/axiosInstances";
import { viaCepApi } from "../../services/viacep";
import InputFormCompound from "src/components/Inputs/inputCompound";
import ButtonFormSubmit from "src/components/Inputs/buttonSubmit";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { object, string } from "yup";
import { checkfield } from "../../helpers/functions";
import { toast, ToastContainer } from "react-toastify";
import InputSenha from "src/components/InputSenha";
import { useNavigate } from 'react-router-dom';
import './style.css';

type Props =  {
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  blurCep?: (event: React.FocusEvent<HTMLInputElement>) => void;
  cep?: string;
  erroClassName?: string;
  inputClassName?: string;
};
type Form = {
  hospital: Array<string>;
  senha: string;
  confirm_senha: string;
  cnpj: string;
};

type ViaCep = {
  cep?: string;
  logradouro?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
};

const schema = object({
  nome: string().required(),
  cnpj: string().required(),
  endereco: string().required(),
  cep: string().required(),
  numero: string().required(),
  bairro: string().required(),
  cidade: string().required(),
  uf: string().required(),
  email: string().email().required(),
  telefone: string().required(),
  whatsapp: string().required(),
  contato: string().required(),
  senha: string().required(),
  confirm_senha: string().required(),
});

export default function Hospital({
  onChange,
  onBlur,
  cep,
  blurCep,
  erroClassName = "px-3 mt-2 border-2 border-red-500 bg-red-100 h-10 w-full",
  inputClassName = "px-3 mt-2 border-2 border-gray-400 h-10 w-full",
}: Props): JSX.Element {
  const [state, setstate] = useState({
    nome: false,
    cnpj: false,
    endereco: false,
    cep: false,
    numero: false,
    bairro: false,
    cidade: false,
    uf: false,
    email: false,
    telefone: false,
    whatsapp: false,
    contato: false,
    senha: false,
    confirm_senha: false,
  });

  const navigate = useNavigate();
  const [changeMask, setChangeMask] = useState<string>("");

  const onBlurBefore = async (event: any, nome?: string) => {
    let erro: boolean = false;
    if (schema) {
      const { name, value } = event.target;
      erro = checkfield(name, schema, { [name]: value });

      setstate({ ...state, [name]: !erro });
    }

    if (onBlur) {
      onBlur(event);
    }
    const { name, value } = event.target;
    if (name === "telefone") {
      if (value.length === 10) {
        setChangeMask("(99)9999-9999");
      } else if (value.length === 11) {
        setChangeMask("(99)99999-9999");
      } else if (value.length === 0) {
        setChangeMask("");
      }
    }
  };

  const [form, setForm] = useState<Form>({
    hospital: [],
    cnpj: "",
    senha: "",
    confirm_senha: "",
  });

  const postForm = (event: any) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (state.confirm_senha || form.senha !== form.confirm_senha) {
      toast.error('Confirme sua senha!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else if (
      !form.cnpj ||
      form.cnpj.includes("_") ||
      form.cnpj === "11.111.111/1111-11" ||
      form.cnpj === "22.222.222/2222-22" ||
      form.cnpj === "33.333.333/3333-33" ||
      form.cnpj === "44.444.444/4444-44" ||
      form.cnpj === "55.555.555/5555-55" ||
      form.cnpj === "66.666.666/6666-66" ||
      form.cnpj === "77.777.777/7777-77" ||
      form.cnpj === "88.888.888/8888-88" ||
      form.cnpj === "99.999.999/9999-99"
    ) {
      toast.error('CNPJ Inválido!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    } else {
      try {
        await axiosApi.post("/hospital", {
          ...form,
          endereco: viacepData?.logradouro,
          bairro: viacepData?.bairro,
          cidade: viacepData?.localidade,
          uf: viacepData?.uf,
        });
        toast.success('Hospital Cadastrado!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
        window.setTimeout(() => {
          navigate("/quem-sou");
        }, 2000);
      } catch (error: any) {
        if (error.response.data.errorInfo) {
          toast.error('Erro no Formulário!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        } else if (error.response.data.errorInfo) {
          toast.error('Preencha o CEP!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
        }
      }
    }
  };

  const [viacepData, setViaCepData] = useState<ViaCep>();

  const cepId = form["cep"];

  const onBlurViaCep = async () => {
    try {
      const { data } = await viaCepApi.get<ViaCep>(`/${cepId}/json`);

      if (data["erro"] === true) {
        setViaCepData(undefined);
        toast.error('CEP inexistente!', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
        setViaCepData({});
      } else {
        setViaCepData(data);
      }
    } catch (error: any) {
      setViaCepData({});
      toast.warn('Digite um CEP válido!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  };

  const [senhaEye, setSenhaEye] = useState(false);
  const [confirmSenhaEye, setconfirmSenhaEye] = useState(false);
  const toggleSenha = () => {
    setSenhaEye(senhaEye ? false : true);
  };
  const toggleConfirmSenha = () => {
    setconfirmSenhaEye(confirmSenhaEye ? false : true);
  };

  return (
    <Fragment>
      {
        window.innerWidth <= 832 &&
        <Link className='floating-back-link' title="Voltar" to="/quem-sou">
          <div className='arrow-bar-top'/><div className='arrow-bar-bottom'/>Voltar
        </Link>
      }
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      <h1 className={`${window.innerWidth <= 832 ? 'form-title ' : ''}flex py-5`}>
        {
          window.innerWidth > 832 &&
          <Link title="Voltar" to="/quem-sou">
            <FaAngleLeft className="mr-3" size="40px" />
          </Link>
        }
        Olá Hospital!
      </h1>

      <p className={`${window.innerWidth <= 832 ? 'form-subtitle ' : ''}px-5 py-1`}>Novo por aqui? Crie seu perfil</p>

      <form onSubmit={handleSubmit} className="hospital-create-account-form bg-fornecedor-mobile">
        <InputForm
          schema={schema}
          name="nome"
          label="Nome*"
          type="text"
          onChange={postForm}
          onBlur={onBlurBefore}
          inputClassName={
            state.nome
              ? `${erroClassName} capitalize`
              : `${inputClassName} capitalize`
          }
          required={true}
          maxlength={50}
        />
        {state.nome && (
          <span className="text-red-600 text-xs px-5">
            *Coloque o nome
          </span>
        )}

        <InputMask
          mask="99.999.999/9999-99"
          onChange={postForm}
          onBlur={onBlurBefore}
        >
          <InputForm
            schema={schema}
            name="cnpj"
            label="CNPJ*"
            type="text"
            inputClassName={state.cnpj ? erroClassName : inputClassName}
            required={true}
            maxlength={28}
          />
        </InputMask>
        {state.cnpj && (
          <span className="text-red-600 text-xs px-5">*Coloque o CNPJ</span>
        )}

        <InputMask mask="99999-999" onChange={postForm} onBlur={onBlurViaCep}>
          <InputForm
            schema={schema}
            name="cep"
            label="CEP*"
            type="text"
            value={cep}
            inputClassName={state.cep ? erroClassName : inputClassName}
            required={true}
            maxlength={18}
          />
        </InputMask>
        {state.cep && (
          <span className="text-red-600 text-xs px-5 erro-span">
            *Coloque seu CEP
          </span>
        )}

        <InputForm
          schema={schema}
          name="endereco"
          label="Endereço*"
          type="text"
          onChange={postForm}
          value={viacepData?.logradouro ?? ""}
          disabled={true}
          inputClassName={state.endereco ? erroClassName : inputClassName}
          required={true}
          maxlength={100}
        />
        {state.endereco && (
          <span className="text-red-600 text-xs px-5 erro-span">
            *Coloque seu endereço
          </span>
        )}

        <div className="flex justify-start w-full">
          <div className="relative">
            <InputFormCompound
              schema={schema}
              name="numero"
              label="Nº*"
              type="text"
              onChange={postForm}
              onBlur={onBlurBefore}
              inputClassName={state.numero ? erroClassName : inputClassName}
              required={true}
            />
            {state.numero && (
              <span className="text-red-600 text-xs erro-span-2">
                *Coloque o número
              </span>
            )}
          </div>

          <div className="relative">
            <InputFormCompound
              schema={schema}
              name="bairro"
              label="Bairro*"
              type="text"
              value={viacepData?.bairro ?? ""}
              onChange={postForm}
              disabled={true}
              inputClassName={
                state.bairro
                  ? `${erroClassName} capitalize`
                  : `${inputClassName} capitalize`
              }
              required={true}
            />
            {state.bairro && (
              <span className="text-red-600 text-xs erro-span-2">
                *Coloque seu bairro
              </span>
            )}
          </div>
        </div>

        <InputForm
          name="complemento"
          label="Complemento"
          type="text"
          onChange={postForm}
          inputClassName={inputClassName}
          required={false}
          maxlength={100}
        />

        <div className="flex justify-start w-full">
          <div className="relative">
            <InputFormCompound
              schema={schema}
              name="cidade"
              label="Cidade*"
              type="text"
              onChange={postForm}
              value={viacepData?.localidade ?? ""}
              disabled={true}
              inputClassName={
                state.cidade
                  ? `${erroClassName} capitalize`
                  : `${inputClassName} capitalize`
              }
              required={true}
            />
            {state.cidade && (
              <span className="text-red-600 text-xs erro-span-2">
                *Coloque sua cidade
              </span>
            )}
          </div>
          <div className="relative">
            <InputFormCompound
              schema={schema}
              name="uf"
              label="Estado(UF)*"
              type="text"
              onChange={postForm}
              disabled={true}
              value={viacepData?.uf ?? ""}
              maxlength={2}
              inputClassName={
                state.uf
                  ? `${erroClassName} uppercase`
                  : `${inputClassName} uppercase`
              }
              required={true}
            />
            {state.uf && (
              <span className="text-red-600 text-xs erro-span-2">
                *Coloque seu estado
              </span>
            )}
          </div>
        </div>

        <div className="flex justify-start w-full">
          <InputMask
            mask={changeMask}
            onChange={postForm}
            onBlur={onBlurBefore}
          >
            <InputFormCompound
              name="telefone"
              label="Telefone"
              type="text"
              required={false}
            />
          </InputMask>

          <div className="relative">
            <InputMask
              mask="(99) 99999-9999"
              onChange={postForm}
              onBlur={onBlurBefore}
            >
              <InputFormCompound
                schema={schema}
                name="whatsapp"
                label="Whatsapp*"
                type="text"
                inputClassName={state.whatsapp ? erroClassName : inputClassName}
                required={true}
              />
            </InputMask>
            {state.whatsapp && (
              <span className="text-red-600 text-xs erro-span-2">
                *Coloque seu Whatsapp
              </span>
            )}
          </div>
        </div>

        <InputForm
          schema={schema}
          onBlur={onBlurBefore}
          name="email"
          label="Email*"
          type="email"
          onChange={postForm}
          required={true}
          inputClassName={state.email ? erroClassName : inputClassName}
          maxlength={50}
        />
        {state.email && (
          <span className="text-red-600 text-xs px-5">*Coloque seu email</span>
        )}
        <InputForm
          schema={schema}
          onBlur={onBlurBefore}
          name="contato"
          label="Pessoa para Contato da Empresa*"
          type="text"
          onChange={postForm}
          required={true}
          inputClassName={
            state.contato
              ? `${erroClassName} capitalize`
              : `${inputClassName} capitalize`
          }
          maxlength={50}
        />
        {state.contato && (
          <span className="text-red-600 text-xs px-5">
            *Coloque o contato da empresa
          </span>
        )}

        <div className="flex justify-start w-full">
          <div className="input-senha-container relative">
            <InputSenha
              schema={schema}
              name="senha"
              label="Senha*"
              senhaEffect={senhaEye}
              onClickEye={toggleSenha}
              pattern=".{6,}"
              onChange={postForm}
              onBlur={onBlurBefore}
              marginTop={"mt-2"}
              inputClass='form-hospital-input-password'
              labelClass='form-hospital-label-password'
              inputClassName={state.senha ? erroClassName : inputClassName}
              width={"px-5"}
            />
            {state.senha && (
              <span className="text-red-600 text-xs erro-span-2 absolute mt-0">
                *Coloque sua senha
              </span>
            )}
          </div>

          <div className="input-senha-container relative">
            <InputSenha
              schema={schema}
              name="confirm_senha"
              label="Confirmar Senha*"
              senhaEffect={confirmSenhaEye}
              onClickEye={toggleConfirmSenha}
              onChange={postForm}
              pattern=".{6,}"
              onBlur={onBlurBefore}
              marginTop={"mt-2"}
              inputClass='form-hospital-input-confirm-password'
              labelClass='form-hospital-label-confirm-password'
              inputClassName={
                state.confirm_senha || form.senha !== form.confirm_senha
                  ? erroClassName
                  : inputClassName
              }
              width={"px-5"}
            />
            {state.confirm_senha ? (
              <span className="text-red-600 text-xs erro-span-2 absolute mt-0">
                *Confirme sua senha
              </span>
            ) : form.senha !== form.confirm_senha ? (
              <span className="text-red-600 text-xs erro-span-2 absolute mt-0">
                *Não corresponde a senha
              </span>
            ) : (
              ""
            )}
          </div>
        </div>

        <ButtonFormSubmit name="btn-submit" type="submit" />
      </form>
    </Fragment>
  );
}
