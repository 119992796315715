import React, { Fragment, useEffect } from "react";
import './style.css'
import ModalConfirmacao from "src/components/ModalConfirmacao";
import { FiMapPin } from "react-icons/fi";
import Loader from "src/components/Loader";
import { MedicosData, Solicitacao } from ".";


interface listaProps {
  medicosData: MedicosData;
  indexNavBar: number;
  fetchMedicosData: () => void;
  loader: boolean;
}

const messageStyle = "font-lato font-black pl-4 p-2 text-atualizar"

const atualizarSyle = "bg-blue  text-white py-1 font-lato font-black rounded-full mt-2  btn-atualizar"

const ListaEquipeMedica: React.FC<listaProps> = ({ medicosData, indexNavBar, fetchMedicosData, loader }) => {


  let indexModifier =
    indexNavBar === 0 ? medicosData.minhaEquipe : indexNavBar === 1 ? medicosData.participacoes : medicosData.solicitacoes


  useEffect(() => {
  }, [])

  return (
    <Fragment>
      <Loader isActive={loader}>
        <div className="flex container-lista">
          <div className="p-vertical flex flex-col gap-2"> 
            {medicosData ?
              medicosData['minhaEquipe'].length === 0 && indexNavBar === 0 ?
                <div className="text-center">
                  <h2 className={messageStyle}>Você não possui equipe. Toque em "adicionar participante" para adicionar doutores e compartilhar sua agenda de cirurgia.</h2>
                  <button onClick={fetchMedicosData} className={atualizarSyle}>Atualizar</button>
                </div>
                :
                medicosData['participacoes'].length === 0 && indexNavBar === 1 ?
                  <div className="justify-center text-center">
                    <h2 className={messageStyle}>Você não possui participações. Quando receber e aceitar solicitação de equipe, a participação aparecerá aqui.</h2>
                    <button onClick={fetchMedicosData} className={atualizarSyle}>Atualizar</button>
                  </div>
                  :
                  medicosData['solicitacoes'].length === 0 && indexNavBar === 2 ?
                    <div className="text-center">
                      <h2 className={messageStyle}>Você não possui solicitações. Quando receber solicitação de equipe, a solicitação aparecerá aqui.</h2>
                      <button onClick={fetchMedicosData} className={atualizarSyle}>Atualizar</button>
                    </div>
                    : null
              : null}
            {medicosData ?
              indexModifier.map((participacao: Solicitacao, index: number) => <div style={{ height: 64 }} className="flex justify-between items-center" key={index}>
                <div className="flex items-center">
                  {participacao['doutor_requisitado']['foto'] ?
                    <img alt="Imagem de Perfil" src={indexNavBar === 0 ? participacao['doutor_requisitado']['foto'] : participacao['doutor_requisitante']['foto']} className="flex w-12 h-12 rounded-full object-cover" />
                    : <svg stroke="currentColor" fill="currentColor" viewBox="0 0 496 512" className="text-gray-400" height="48px" width="48px" xmlns="http://www.w3.org/2000/svg">
                      <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path>
                    </svg>
                  }
                  {
                    indexNavBar !== 0 ?
                      <div className="pl-4">
                        <h2 className="nomesClass font-lato font-black">
                          {participacao['doutor_requisitante']['nome']}
                        </h2>
                        {participacao['doutor_requisitante']['cidade'] ?
                          <div className="flex items-center">
                            <FiMapPin className="pr-1 pinIcon" />
                            <p className="font-normal justify-center lista-cidade">
                              {`${participacao['doutor_requisitante']['cidade']} - ${participacao['doutor_requisitante']['uf']}`}
                            </p>
                          </div> : null}
                      </div>
                      : <div className="flex flex-col container-medico">
                        <h2 className="nomesClass font-lato font-black">
                          {participacao['doutor_requisitado']['nome']}
                        </h2>
                        {participacao['doutor_requisitado']['cidade'] ?
                          <div className="flex items-center">
                            <FiMapPin className="pr-1 pinIcon" />
                            <p className="font-normal lista-cidade">
                              {`${participacao['doutor_requisitado']['cidade']} - ${participacao['doutor_requisitante']['uf']}`}
                            </p>
                          </div> : null}
                      </div>}
                </div>
                <div className="flex items-center">
                  <ModalConfirmacao
                    indexNavBar={indexNavBar}
                    fetchMinhaEquipe={fetchMedicosData}
                    id={participacao['id']}
                    solicitacaoId={participacao['solicitacao_status_id']} />
                </div>
              </div>) : null
            }
          </div>
        </div>
      </Loader>
    </Fragment>
  );
}

export default ListaEquipeMedica;