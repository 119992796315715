import "./style.css";
import React from "react";

type Props = {
  titulo: string;
  className?: string;
};

// eslint-disable-next-line no-empty-pattern
export default function Titulos({ titulo, className }: Props): JSX.Element {
  return (
    <div className="font-bold">
      <h1 className={`pt-20 flex title-prox-cirugias ${className}`}>{titulo}</h1>
    </div>
  );
}