import "./style.css";
import React, { Fragment, useState } from "react";
import Header
  // { MobileHeader }
  from "src/components/Header";
import SideBar from "src/components/SideBar";
type Props = {
  children: Array<JSX.Element> | JSX.Element;
};

export default function HomeLayout({ children }: Props): JSX.Element {

  const [isSidebarVisible, setSidebarVisible] = useState<boolean>(false);
  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev);
    // console.log(isSidebarVisible);
  }

  return (
    <Fragment>
      <div className="">
        <Header toggleSidebar={toggleSidebar} />
      </div>
      <div>
        <SideBar setSidebarVisible={setSidebarVisible}
          isSidebarVisible={isSidebarVisible} />
        <div className="contentContainer">
          {children}
        </div>
      </div>
    </Fragment>
  );
}

// export const MobileHomeLayout = ({ children }: Props) => {

//   return (
//     <div className='mobile-home-layout-wrapper'>
//       <div className='mobile-home-layout-container'>
//         <MobileHeader/>
//         <div className='mobile-home-main-container'>{children}</div>
//       </div>
//     </div>
//   );
// }