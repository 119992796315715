import React, { useEffect, useState } from "react";
import { navProps } from "./interfaces";


const SolicitacaoNavBar: React.FC<navProps> = ({ index, setIndex }) => {

   const [windowCheck, setWindowCheck] = useState(window.innerWidth > 833);
  
    useEffect(() => {
      const handleResize = () => {
        setWindowCheck(window.innerWidth > 833);
      };
  
      window.addEventListener('resize', handleResize);
      // Remova o listener quando o componente for desmontado
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  

  return (
    <div className={`flex wrap items-center mobile-equipe-navigator ${!windowCheck ? '' : 'p-navigator'}`}>
      <div className="equipe-medica-nav-container">
        <button className={`${index === 0 ? 'active' : null} px-3 rounded-md link-nav lg:text-md md:text-md xl:text-xl`} onClick={() => setIndex(0)}>Identificação</button>
        <button className={`${index === 1 ? 'active' : null} px-3 link-nav lg:text-md md:text-md xl:text-xl btn-new-vendedor-border-x`} onClick={() => setIndex(1)}>Informações</button>
        <button className={`${index === 2 ? 'active' : null} px-3 rounded-md link-nav lg:text-md md:text-md xl:text-xl`} onClick={() => setIndex(2)}>Procedimento</button>
      </div>
    </div>
  );
}

export default SolicitacaoNavBar;

