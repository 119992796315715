import React, { useEffect, useState } from "react";
import SolicitacaoNavBar from "./SolicitacaoNavBar";
import Identificacao from "./Identificacao";
import Informacoes from "./Informacoes";
import Procedimento from "./Procedimento";
import Modelos from "./Modelos";
import Buttons from "./Buttons";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { axiosApi } from "src/services/axiosInstances";
import { opmeList, cbhpmSelectedList, layoutProps } from "./interfaces";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import SimulacaoTable from "./SimulacaoTable";
import Loader from "src/components/Loader";


const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ]+(?:\s+[A-Za-zÀ-ÖØ-öø-ÿ]+)+$/;
// const phoneNumberRegex = /^\(\d{2}\)\d{4,5}-\d{4}$/;


const identifySchema = yup.object({
  nome: yup.string().required("Campo obrigatório").matches(nameRegex, 'Formato de nome Inválido'),
  whatsapp: yup.string().required("Campo obrigatório"),
  // .matches(phoneNumberRegex, 'Numero de telefone inválido'),
  email: yup.string().required("Campo obrigatório").email('Formato de e-mail inválido'),
  carater_atendimento_id: yup.string().required("Campo obrigatório"),
  hospital_nome: yup.string().required("Campo obrigatório"),
  tipo_internacao_id: yup.string().required("Campo obrigatório"),
  regime_internacao_id: yup.string().required("Campo obrigatório"),
  quantidade_diarias_solicitadas: yup.string().required("Campo Obrigatório"),
  solicitacao_cirurgia_convenio_id: yup.string().required("Selecione um convênio listado"),
  indicacao_clinica: yup.string().required("Campo Obrigatório").min(10, "Mínimo de 10 caracteres"),
  cbhpm: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      quantidade: yup.string().required(),
    })
  ).min(1, "Você deve selecionar ao menos um cbhpm"),
});

const LayoutSolicitacao: React.FC<layoutProps> = ({ index, setIndex }) => {

  const [parametros, setParametros] = useState([]);
  const [hospitalInputValue, setHospitalInputValue] = useState("")
  const [cidInputValue, setCidInputValue] = useState("");
  const [secondCidInputValue, setSecondCidInputValue] = useState("");
  const [modelos, setModelos] = useState([])
  const [opmeSelectedList, setOpmeSelectedList] = useState<opmeList[]>([]);
  const [cbhpmSelectedList, setCbhpmSelectedList] = useState<cbhpmSelectedList[]>([])
  const [convenioInputValue, setConvenioInputValue] = useState("")
  const [nomeModelo, setNomeModelo] = useState("");
  const [loader, setLoader] = useState(false);

  const getParametros = async () => {
    try {
      const { data } = await axiosApi.get(`/solicitacao-cirurgia/parametrosSolicitacaoCirurgia`);
      setParametros(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getModelos = async () => {
    try {
      const { data } = await axiosApi.get(`/solicitacao-cirurgia/modelo`);
      setModelos(data);
    } catch (error) {
      console.log(error);
    }
  };

  const methods = useForm({ resolver: yupResolver(identifySchema) });
  const { handleSubmit: onSubmit
    , getValues
  } = methods;


  const navigate = useNavigate()
  const pushCirurgiasPage = () => {
    navigate('/autorizacoes')
  }

  const delayExecution = (navigateFunction: () => void, delay: number) => setTimeout(navigateFunction, delay);

  const postSolicicaoCirurgia = async (data: any) => {
    try {
      setLoader(true);
      const response = await axiosApi.post('/solicitacao-cirurgia', data)
      toast.success(`Cirurgia Solicitada com Sucesso`, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      if (response.data && response.data.url_guia_solicitacao) {
        window.open(response.data.url_guia_solicitacao, '_blank');
      }
      delayExecution(pushCirurgiasPage, 3000);
      return response.data;
    } catch (error) {
      console.error('Erro na requisição:', error);
      throw error;
    } finally {
      setLoader(false);
    }
  }


  const handleSubmit = async (data: any) => {
    const
      {
        whatsapp,
        tipo_internacao_id,
        regime_internacao_id,
        carater_atendimento_id,
        quantidade_diarias_solicitadas,
        solicitacao_cirurgia_convenio_id
      } = data;

    const newPhone = whatsapp.replace(/[^0-9]/g, '');

    data.whatsapp = parseInt(newPhone);
    data.tipo_internacao_id = parseInt(tipo_internacao_id);
    data.regime_internacao_id = parseInt(regime_internacao_id);
    data.carater_atendimento_id = parseInt(carater_atendimento_id);
    data.quantidade_diarias_solicitadas = parseInt(quantidade_diarias_solicitadas);
    data.solicitacao_cirurgia_convenio_id = parseInt(solicitacao_cirurgia_convenio_id)
    // console.log(data);
    await postSolicicaoCirurgia(data);
    console.log(data, "post realizado com sucesso");
  }

  useEffect(() => {
    getParametros();
    getModelos();
  }, [])


  const handleValidation = () => {
    const {
      nome, email, whatsapp,
      carater_atendimento_id,
      hospital_nome, regime_internacao_id,
      solicitacao_cirurgia_convenio_id,
      quantidade_diarias_solicitadas, tipo_internacao_id, indicacao_clinica
    } = getValues();

    const identificacaoValidation =
      !nome ||
      !email ||
      !whatsapp ||
      !hospital_nome ||
      !solicitacao_cirurgia_convenio_id

    const informacoesValidation =
      !carater_atendimento_id ||
      !regime_internacao_id ||
      !quantidade_diarias_solicitadas ||
      !tipo_internacao_id ||
      !indicacao_clinica

    const procedimentoValidation = cbhpmSelectedList.length === 0

    const nameFormat = !nome.match(nameRegex);

    switch (true) {
      case identificacaoValidation || nameFormat:
        setTimeout(() => setIndex(0), 50);
        // setIndex(0);
        break;
      case informacoesValidation:
        setTimeout(() => setIndex(1), 50);
        // setIndex(1);
        break;
      case procedimentoValidation:
        setTimeout(() => setIndex(2), 50);
        // setIndex(2);
        break;
      default:
        break;
    }
  }


  return (

    <>
      <Loader isActive={loader}>
        <div className="background-mobile p-12 p-12-mobile width-lock">
        <h2 className="secretariado-title font-black title-mobile">Solicitação de Cirurgia</h2>
          <FormProvider {...methods}>
            <Modelos
              modelos={modelos}
              getModelos={getModelos}
              setCbhpmSelectedList={setCbhpmSelectedList}
              setOpmeSelectedList={setOpmeSelectedList}
              setHospitalInputValue={setHospitalInputValue}
              setNomeModelo={setNomeModelo}
              nomeModelo={nomeModelo}
              setConvenioInputValue={setConvenioInputValue}
              setCidInputValue={setCidInputValue}
              setSecondCidInputValue={setSecondCidInputValue}
            />
            <SolicitacaoNavBar index={index} setIndex={setIndex} />
            <form className="flex flex-col gap-4 pt-4" onSubmit={onSubmit(handleSubmit)}>
              {
                index === 0 ? <Identificacao
                  convenioInputValue={convenioInputValue}
                  setConvenioInputValue={setConvenioInputValue}
                  setHospitalInputValue={setHospitalInputValue}
                  hospitalInputValue={hospitalInputValue}
                />
                  :
                  index === 1 ? <Informacoes
                    cidInputValue={cidInputValue}
                    setCidInputValue={setCidInputValue}
                    secondCidInputValue={secondCidInputValue}
                    setSecondCidInputValue={setSecondCidInputValue}
                    parametros={parametros}
                  />
                    :
                    <Procedimento
                      cbhpmSelectedList={cbhpmSelectedList}
                      setCbhpmSelectedList={setCbhpmSelectedList}
                      opmeSelectedList={opmeSelectedList}
                      setOpmeSelectedList={setOpmeSelectedList}
                      parametros={parametros}
                    />
              }
                <Buttons
                  setIndex={setIndex}
                  handleValidation={handleValidation}
                  getModelos={getModelos}
                  index={index}
                  nomeModelo={nomeModelo}
                  setNomeModelo={setNomeModelo}
                />        
              {/* {index === 2 && <SimulacaoTable />} */}
            </form>

          </FormProvider>
        </div>
        <ToastContainer />
      </Loader>
    </>
  )
}


export default LayoutSolicitacao;