import "./style.css";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { AuthState, Doutor, AuthAction, Usuario } from "src/store/types";
import * as auth from "src/store/actions/auth";
import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "redux";
import ModalPerfil from "../ModalPerfil";
import DropdownHeader from "../DropdownHeader";
import DropdownNotifications from "../DropdownNotifications";
// import whiteLogo from '../../assets/MobileHeaderMenu/White-Logo.svg';
// import myProfile from '../../assets/MobileHeaderMenu/My-Profile.svg';
// import auxiliaries from '../../assets/MobileHeaderMenu/Auxiliaries.svg';
// import notifications from '../../assets/MobileHeaderMenu/Notifications.svg';
// import exit from '../../assets/MobileHeaderMenu/Exit.svg';
// import userImage from "../../assets/MobileHeaderMenu/MobileProfile/User-Image.svg";
// import MobileProfile from "../MobileProfile";
// import MobileFloatingNav from "../MobileFloatingNav";
// import MobileNotifications from "../MobileNotifications";
import AssinaturaDigital from "../AssinaturaDigital";
import Select from "react-select";
import { selectStyle } from "src/helpers/functions";
// import userPng from "../../assets/1645620449.png";

const mapStateToProps = ({ auth }: { auth: AuthState }): AuthState => {
  return {
    usuario: auth.usuario,
    doutor: auth.doutor,
    assinaturaDigital: auth.assinaturaDigital,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AuthAction>) => {
  return {
    setDoutor: (doutor: Doutor) => dispatch(auth.setDoutor(doutor)),
    setUpdatePerfil: (usuario: Usuario) =>
      dispatch(auth.setUpdatePerfil(usuario)),
  };
};

type Option = {
  value: string | number;
  label: string;
};



const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;


type Props = PropsFromRedux & {
  navbarClassName?: string;
  navbarItensClassName?: string;
  navitensFlexClassName?: string;
  navLinkClassName?: string;
  navIconClassName?: string;
  headerBodyClassName?: string;
  contentNavbarClassName?: string;
  logoContentClassName?: string;
  logoImgClassName?: string;
  nomeLogoClassName?: string;
  spanBoldLogoClassName?: string;
  spanLogoClassName?: string;
  selectmedicoClassName?: string;
  displayNoneSelect?: string;
  userContainerClassName?: string;
  btnNotificationsClassName?: string;
  btnUserImgClassName?: string;
  imgUserClassName?: string;
  nomeUserClassName?: string;
  dropdownClassName?: string;
  dropdownContentClassName?: string;
  dropdownLinkClassName?: string;
  id?: any;
  nome?: any;
  tel?: any;
  wp?: any;
  email?: any;
  senha?: any;
  actDropdown?: boolean;
  onOpenPerfil?: () => void;
  toggleSidebar: () => void;
};

function Header({
  navbarClassName = window.innerWidth < 833 ? "mobile-header-container" : "z-header fixed w-full bg-white px-10 flex justify-end items-center",
  headerBodyClassName = "",
  contentNavbarClassName = "relative flex items-center justify-between h-18",
  logoContentClassName = "flex justify-start",
  userContainerClassName = "inset-y-0 right-0 flex items-center pr-0 sm:static sm:inset-auto sm:ml-6 sm:pr-0",
  btnUserImgClassName = "flex text-sm border-2 border-transparent rounded-full",
  usuario,
  assinaturaDigital,
  toggleSidebar,
  setDoutor,
  doutor,
  selectmedicoClassName = "text-base lg:text-md md:text-sm xl:text-base font-bold",

}: Props): JSX.Element {

  const doutorCheck = usuario?.nivel === 1;
  const hospitalCheck = usuario?.nivel === 6;
  const [windowCheck, setWindowCheck] = useState(window.innerWidth > 833);
  const location = useLocation()
  const toggleSolicitarCirurgia = location.pathname !== "/autorizacoes/solicitar-cirurgia"
   const secretariaCheck = usuario.nivel === 3;


    const usuariosOptions = React.useMemo<Array<Option>>((): Array<Option> => {
      const options: Array<Option> | undefined = usuario?.doutores.map(
        (doutor: Doutor): Option => {
          return {
            value: doutor.id,
            label: doutor.nome,
          };
        }
      );
  
      return [{ value: 0, label: "Visão geral" }, ...(options ?? [])];
    }, [usuario]);
  
    const doutorNomeSelect = (event: any) => {
      setDoutor({ nome: event.label, id: event.value });
    };

  useEffect(() => {
    const handleResize = () => {
      setWindowCheck(window.innerWidth > 833);
    };

    window.addEventListener('resize', handleResize);
    // Remova o listener quando o componente for desmontado
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [openPerfil, setOpenPerfil] = useState(false);
  const toggelPerfil = () => setOpenPerfil(!openPerfil);
  const perfilOpen = (): void => {
    setOpenPerfil(true);
    setToggle(!toggle);
  };




  const [toggle, setToggle] = useState(false);
  const [togglenf, setToggleNf] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const dropdownNf = useRef<HTMLDivElement>(null);

  const closeDrop = () => {
    setToggle(!toggle);
  };

  const handleOutsideClick = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target))
      setToggle(false);
  };

  const handleOutsideClickNf = (event: any) => {
    if (dropdownNf.current && !dropdownNf.current.contains(event.target))
      setToggleNf(false);
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClickNf, true);
    return () => {
      document.removeEventListener("click", handleOutsideClickNf, true);
    };
  }, [dropdownNf]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [dropdownRef]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, true);
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [dropdownRef]);

  return (
    <Fragment>
      <ModalPerfil
        open={openPerfil}
        setOpenModal={setOpenPerfil}
        openModal={openPerfil}
        toggle={toggelPerfil}
      />
      <div className={headerBodyClassName}>
        <nav className={navbarClassName}>
          {!windowCheck &&
            <>
              <div onClick={toggleSidebar}
                className='hamburger-container m-6'>
                <div className='hamburger-bar' />
                <div className='hamburger-bar' />
                <div className='hamburger-bar' />
              </div>
              
              { !doutorCheck && 
                <div style={{marginLeft: 140}}>
                {!secretariaCheck && <p className="font-bold text-blue self-end text-center">{usuario?.nome}</p>}
                { secretariaCheck &&
                  <Select
                            className={'text-base lg:text-md md:text-sm xl:text-base font-bold'}
                            options={usuariosOptions}
                            onChange={doutorNomeSelect}
                            defaultValue={{
                              value: doutor?.id ?? "",
                              label: doutor?.nome ?? "",
                            }}
                            styles={selectStyle}
                          />

                }

              </div>
              }
            </>
          }


          <div className={contentNavbarClassName}>

            <div className={logoContentClassName}>
            </div>

            <div className={userContainerClassName}>
              {doutorCheck ?
                <>
                  {
                    <AssinaturaDigital
                      certificado={assinaturaDigital}
                      toggleSolicitarCirurgia={toggleSolicitarCirurgia}
                    />
                  }
                  {toggleSolicitarCirurgia && <Link to="autorizacoes/solicitar-cirurgia">
                    <button className="btn-new-vendedor bg-blue hover:bg-blue-700 text-white py-1 px-3 font-lato font-black rounded-full m-6 adicionar-btn">
                      Solicitar Cirurgia
                    </button>
                  </Link>
                  }
                </> : null
              }
              {!hospitalCheck && windowCheck &&
                <DropdownNotifications
                  toggleDropdown={() => setToggleNf(!togglenf)}
                  toggleDrop={togglenf}
                  dropRef={dropdownNf}
                />
              }

              {windowCheck && <div className="ml-5 relative btn-dropdown flex justify-end">
                <div>
                  <button
                    className={btnUserImgClassName}
                    id="user-menu"
                    aria-label="User menu"
                    aria-haspopup="true"
                  >
                    <div onClick={() => setOpenPerfil(true)}>
                      {!usuario?.foto ? (
                        <FaUserCircle size={"48px"} className="text-gray-400" />
                      ) : (
                        <img
                          src={usuario?.foto || undefined}
                          style={{ width: "48px", height: "48px" }}
                          className="rounded-full border-solid border-white border-2 object-cover object-center"
                          alt=""
                        />
                      )}
                    </div>
                    <DropdownHeader
                      toggleDropdown={() => setToggle(!toggle)}
                      userName={usuario?.nome}
                      openModal={perfilOpen}
                      toggleDrop={toggle}
                      dropRef={dropdownRef}
                      closeDrop={closeDrop}
                    />
                  </button>

                </div>

              </div>
              }
            </div>
          </div>
        </nav>
      </div>
    </Fragment>
  );
}

// const MobileHeaderComponent = ({
//   usuario,
// }: any) => {
//   const location = useLocation();

//   const [showMenu, setShowMenu] = React.useState(false);
//   const [profileState, setProfileState] = React.useState(-1);
//   const [notificationState, setNotificationState] = React.useState(-1);

//   return (
//     <>
//       <div className='mobile-header-container'>
//         <div onClick={() => setShowMenu(true)} className='hamburger-container'>
//           <div className='hamburger-bar' />
//           <div className='hamburger-bar' />
//           <div className='hamburger-bar' />
//         </div>
//         <div className='mobile-header-content-container'>
//           <span className='mobile-header-content'>Bem vindo(a) de volta</span>
//           <span className='mobile-header-info'>{usuario?.nome}</span>
//         </div>
//       </div>
//       {
//         showMenu &&
//         <div className='mobile-header-menu-container'>
//           <div onClick={() => setShowMenu(false)} className='mobile-header-menu-top-container'>
//             <div className='cross-container'>
//               <div className='cross-bar-left' />
//               <div className='cross-bar-right' />
//             </div>
//             <span className='mobile-header-menu-close-content'>Fechar</span>
//           </div>
//           <div className='mobile-header-menu-center-container'>
//             <ul className='mobile-header-menu-list-container'>
//               <li className='mobile-header-menu-option'>
//                 <div className="mobile-profile-user-image-container">
//                   {usuario?.foto ?
//                     <img
//                       src={usuario?.foto}
//                       className='mobile-profile-user-image'
//                       alt=""
//                     />

//                     :
//                     <img className='mobile-profile-empty-user-image' src={userImage} alt="user-img" />
//                   }
//                   {/* <img src={usuario?.foto ? usuario.foto : userImage} className="mobile-header-user-img" alt='user-img' /> */}
//                 </div>
//                 <span className='mobile-header-menu-option-user-content'>{usuario?.nome}</span>
//               </li>
//               <li onClick={() => { setProfileState(1) }} className='mobile-header-menu-option'>
//                 <img src={myProfile} alt="user-icon" />
//                 <span className='mobile-header-menu-option-content'>Meu Perfil</span>
//               </li>
//               {location.pathname !== '/auxiliares' ?
//                 <Link to='/auxiliares' onClick={() => { setShowMenu(false) }} className='mobile-header-menu-option'>
//                   <img src={auxiliaries} alt="gear-icon" />
//                   <span className='mobile-header-menu-option-content'>Auxiliares</span>
//                 </Link>
//                 :
//                 ("")
//               }
//               <li onClick={() => { setNotificationState(1) }} style={{ position: 'relative' }} className='mobile-header-menu-option'>
//                 <img src={notifications} alt="bell-icon" />
//                 <div className='mobile-header-menu-notification-badge' />
//                 <span className='mobile-header-menu-option-content'>Notificações</span>
//               </li>
//               <Link to='/logout' className='mobile-header-menu-option'>
//                 <img src={exit} alt="exit-icon" />
//                 <span className='mobile-header-menu-option-content'>Sair</span>
//               </Link>
//             </ul>
//             <div className='mobile-header-menu-end-logo'>
//               <img className='mobile-header-menu-logo' src={whiteLogo} alt="logo" />
//             </div>
//           </div>
//         </div>
//       }
//       <MobileFloatingNav state={profileState} />
//       <MobileNotifications state={notificationState} setState={setNotificationState} />
//       <MobileProfile state={profileState} setState={setProfileState} />
//     </>
//   );
// }

export default connector(Header);
// export const MobileHeader = connector(MobileHeaderComponent);