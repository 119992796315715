import React from "react";
import { useLocation } from "react-router-dom";
import { ObjectSchema } from "yup";
import './media.css';
// import { checkfield } from "../../helpers/functions";

type Props = {
  label: string;
  name: string;
  labelClassName?: string;
  wrapClassName?: string;
  inputClassName?: string;
  type: string;
  required?: boolean;
  ref?: any;
  maxlength?: number;
  minlength?: number;
  schema?: ObjectSchema<any>;
  disabled?: boolean;
  value?: string;
  pattern?: string;
  title?: string;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
};

export default function InputForm({
  label,
  name,
  type,
  required,
  ref,
  maxlength,
  minlength,
  disabled,
  labelClassName = "text-gray-800 font-bold text-sm xl:text-lg md:text-base",
  wrapClassName = "px-5 pt-4",
  inputClassName = "px-3 mt-2 capitalize border-2 border-gray-400 w-full h-10",
  schema,
  value,
  onChange,
  onBlur,
  pattern,
  title,
}: Props) {
  const location = useLocation();

  return (
    <div className={wrapClassName}>
      <label className={`input-form-label${location.pathname === '/solicitacao' ? 'patient' : ''} ${window.location.pathname === '/recuperar-senha' ? 'text-blue' : ''} ${labelClassName}`} htmlFor="">
        {label}
      </label>
      <p></p>
      <input
        className={`input-form-input ${inputClassName}`}
        type={type}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        translate="no"
        ref={ref}
        maxLength={maxlength}
        minLength={minlength}
        autoComplete="off"
        disabled={disabled}
        value={value}
        pattern={pattern}
        title={title}
        style={{ borderRadius: "16px" }}
      />
    </div>
  );
}
