import React, { useState } from "react";
import ModalSalvarModelo from "src/components/ModalSalvarModelo";
import { buttonProps } from "./interfaces";


const Buttons: React.FC<buttonProps> = ({ index, setIndex, getModelos, handleValidation, nomeModelo, setNomeModelo }) => {

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleNext = () => {
    if (index === 0) {
      setIndex(1);
    } else if (index === 1) {
      setTimeout(()=> {setIndex(2)}, 50);
    }
  };

  return (
    <div style={{ maxWidth: 801 }} className={`${index === 0 ? '' : null} flex justify-between`}>

      <button onClick={openModal}
        type="button"
        className="border border-blue-700 bg-white text-blue-700 py-1 px-3 font-lato font-black rounded-full adicionar-btn">
        Salvar Como Modelo
      </button>

      <ModalSalvarModelo
        getModelos={getModelos}
        isOpen={isOpen}
        closeModal={closeModal}
        nomeModelo={nomeModelo}
        setNomeModelo={setNomeModelo}
      />
      { index === 2 ?  
      <button onClick={handleValidation} className="bg-blue hover:bg-blue-700 text-white py-1 px-3 font-lato font-black rounded-full adicionar-btn">
        {`Confirmar Solicitação (3/3)`}
      </button> : 
      <button
      // style={{maxWidth: 182}}
      className="bg-blue hover:bg-blue-700 text-white py-1 px-3 font-lato font-black rounded-full adicionar-btn"
      type="button" onClick={handleNext}>
        {index === 0 ? `Próximo (1/3)` : index === 1 ? `Próximo (2/3)` : null}
      </button>
      }

    </div>
  )
}


export default Buttons;