import React, { useEffect, useState, useRef } from 'react';
import './style.css';
import Popup from 'reactjs-popup';
import IntroAssinatura from './IntroAssinatura';
import Vidas from '../CertificadosAssinatura/Vidas';
import Birdid from '../CertificadosAssinatura/Birdid';
import VidasRequest from '../CertificadosAssinatura/Vidas/VidasRequest';
import CancelAssinature from '../CertificadosAssinatura/CancelAssinature';
import BirdidConfirmation from '../CertificadosAssinatura/Birdid/BirdidConfirmation';
import vidas from '../../assets/vidas2.png';
import birdid from '../../assets/birdid2.png';
import { axiosApi } from 'src/services/axiosInstances';
import { toast } from 'react-toastify';

interface ExpiresIn {
  days: number;
  hours: number;
}

interface ModalAssinaturaDigitalProps {
  isOpen: boolean;
  closeModal: () => void;
  certificadoIsActive?: boolean;
  certificadorId: number | null;
  setToggled: (state: boolean) => void;
  setCertificadorId: (state: number) => void;
  expiresIn: ExpiresIn;
  checkCertificate: () => void;
}

const ModalAssinaturaDigital: React.FC<ModalAssinaturaDigitalProps> = ({
  isOpen,
  closeModal,
  certificadoIsActive,
  certificadorId,
  setToggled,
  setCertificadorId,
  expiresIn,
  checkCertificate
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentScreen, setCurrentScreen] = useState<'intro' | 'vidas' | 'birdid' | 'birdid-screen-2' | 'birdid-expires-in' | 'vidas-screen-2' | 'vidas-expires-in'>('intro');
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);


  const startPolling = () => {
    const interval = 5000;
    const duration = 30000;
    const endTime = Date.now() + duration;

    const poll = async () => {
      if (Date.now() >= endTime) {
        toast.error(`Tempo esgotado. Por favor, tente novamente.`, {
          position: "top-center",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        stopPolling();
        return;
      }

      try {
        const response = await axiosApi.get('/certificado');
        if (response && Object.keys(response.data).length > 0) {
          setLoading(false);
          setCurrentScreen('vidas-expires-in');
          toast.success(`Assinatura digital verificada com sucesso`, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          stopPolling();
        }
      } catch (error) {
        console.log(error);
      }
    };

    pollingIntervalRef.current = setInterval(poll, interval);
    poll();
  };

  const stopPolling = () => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }
  };

  const vidasRequest = async () => {
    const body = {
      certificador_id: 1
    };
    try {
      stopPolling();
      setLoading(true);
      const response = await axiosApi.post('/certificado', body);
      setCurrentScreen('vidas-screen-2');
      startPolling();
      console.log(response);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const closeModalCheckCertificate = () => {
    stopPolling();
    checkCertificate();
    !certificadorId && setToggled(false);
    closeModal();
  };

  useEffect(() => {
    if (isOpen) {
      if (certificadorId !== 0) {
        switch (certificadorId) {
          case 1:
            setCurrentScreen('vidas-expires-in');
            break;
          case 2:
            setCurrentScreen('birdid-expires-in');
            break;
          default:
            setCurrentScreen('intro');
        }
      } else {
        setCurrentScreen('intro');
      }
    } else {
      setCurrentScreen('intro');
    }
  }, [isOpen, certificadorId]);

  const renderScreen = () => {
    switch (currentScreen) {
      case 'intro':
        return <IntroAssinatura
          goToVidas={() => setCurrentScreen('vidas')}
          goToBirdid={() => setCurrentScreen('birdid')}
        />;
      case 'vidas':
        return <Vidas
          vidasRequest={vidasRequest}
          loading={loading}
          goToVidasRequest={() => setCurrentScreen('vidas-screen-2')}
          goToVidasCancel={() => setCurrentScreen('vidas-expires-in')}
        />;
      case 'vidas-screen-2':
        return <VidasRequest
          vidasRequest={vidasRequest}
          goToVidasCancel={() => setCurrentScreen('vidas-expires-in')}
        />;
      case 'vidas-expires-in':
        return <CancelAssinature logo={vidas} brand={'vidas'} closeModal={closeModal} setToggled={setToggled} setCertificadorId={setCertificadorId} expiresIn={expiresIn} />;
      case 'birdid':
        return <Birdid
          goToBirdidConfirmation={() => setCurrentScreen('birdid-screen-2')}
        />;
      case 'birdid-screen-2':
        return <BirdidConfirmation
          goToBirdidCancel={() => setCurrentScreen('birdid-expires-in')} checkCertificate={checkCertificate}
        />
      case 'birdid-expires-in':
        return <CancelAssinature logo={birdid} brand={'birdid'} closeModal={closeModal} setToggled={setToggled} setCertificadorId={setCertificadorId} expiresIn={expiresIn} />
      default:
        return null;
    }
  };


  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <Popup
      closeOnDocumentClick={false}
      open={isOpen}
      onClose={closeModal}
    >
      <div ref={modalRef} className="modal-assinatura">
        <div className="flex justify-end">
          <button className="font-black" onClick={closeModalCheckCertificate}>
            X
          </button>
        </div>
        <div className="modal-header">
          <h3 className="font-lato font-black text-2xl assinatura-tittle">Assinatura Digital</h3>
        </div>
        {renderScreen()}
      </div>
    </Popup>
  );
};

export default ModalAssinaturaDigital;
