import React, { Fragment, useEffect, useState } from "react";
import "./style.css";
import { axiosApi } from "src/services/axiosInstances";
import ModalSecretariado from "src/components/ModalSecretariado";
import SecretarioCards from "src/components/SecretarioCards";
import Secretaria from "../Secretaria";
import Loader from "src/components/Loader";


interface Fotos {
  url: string;
}

interface Usuario {
  fotos: Fotos;
  email: string;
}

interface Secretaria {
  id: number;
  email_secretaria: string;
  secretarias: {
    nome: string;
    usuario: Usuario;
  };

}

interface UserData {
  nome: string;
  email_secretaria: string;
  usuario: {
    fotos: {
      url: string;
    };
  };
}


const SecretariadoPage = () => {

  const [email, setEmail] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [loaderMain, setLoaderMain] = useState<boolean>(false);
  const [secretarioList, setSecretarioList] = useState<Secretaria[]>([]);
  const [modalIndex, setModalIndex] = useState<number>(0)
  const [secretarioToDelete, setSecretarioToDelete] = useState<Secretaria | null>(null);

  const closeModal = () => setIsOpen(false);

  const loadSecretarios = async () => {
    setLoaderMain(true);
    try {
      const response = await axiosApi.get('doutorSecretaria');
      setSecretarioList(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoaderMain(false)
    }
  }

  const onDelete = (secretaria: Secretaria) => {
    setSecretarioToDelete(secretaria);
    setModalIndex(2);
    setIsOpen(true);
  };

  const deleteSecretario = async (id: number) => {
    try {
      await axiosApi.delete(`doutorSecretaria/${id}`);
      loadSecretarios();
      closeModal();
    } catch (error) {
      console.error('Erro ao deletar secretário:', error);
    }
  };


  const buscaSecretario = async () => {
    setIsOpen(true);
    setLoader(true);
    try {
      const body =
      {
        email_secretaria: email
      };
      const response = await axiosApi.post('doutorSecretaria/check', body);
      setModalIndex(0);
      setUserData(response.data);
    } catch (error: any) {
      if (error.message.includes('404')) {
        setModalIndex(1);
        console.log('Email não cadastrado. Por favor, tente novamente.');
      }
      console.error('Erro:', error);
    } finally {
      setLoader(false);
    }
  };

  const addSecretario = async () => {
    setLoader(true);
    try {
      const body =
      {
        email_secretaria: email
      };
      await axiosApi.post('doutorSecretaria', body);
    } catch (error) {
      console.error('Erro:', error);
    } finally {
      setLoader(false);
      closeModal();
      loadSecretarios();
    }
  };

  useEffect(() => {
    loadSecretarios();

  }, [])

  return (
    <Fragment>
      <Loader
        fixed={false}
        isActive={loaderMain}>
        <div className="p-12 background-mobile p-12-mobile">
          <h2 className="secretariado-title font-black title-mobile">Secretariado</h2>
          <div className="flex flex-col justify-start max-w-lg">
            <p className="secretariado-info-message text-lg text-center pt-10 max-w-lg self-center">
              Os emails vinculados deverão concluir cadastro e terão acesso
              a todas as suas informações através do Dashboard Web em:
              https://app.cirurgiaautorizada.com.br
            </p>
            <form className="flex flex-col justify-center" onSubmit={(e) => { e.preventDefault(); buscaSecretario(); }}>
              <input
                style={{ height: 44, width: 355, borderRadius: 16 }}
                className="email-secretariado-input mt-5 pl-4 items-center justify-center border mx-auto"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
              <button
                type="submit"
                className="vincular-email-button self-center font-bold rounded-full shadow-lg mt-5"
                onClick={() => setModalIndex(0)}
              >
                Vincular Email
              </button>
            </form>
            <ModalSecretariado
              isOpen={isOpen}
              userData={userData}
              loader={loader}
              closeModal={closeModal}
              addSecretario={addSecretario}
              modalIndex={modalIndex}
              email={email}
              secretarioToDelete={secretarioToDelete}
              deleteSecretario={deleteSecretario}
            />
          </div>

          <div style={{ color: '#4F4F4F' }} className="pt-10 pb-5">
            <p>Secretários Vinculados:</p>
            <div>
              {secretarioList.length !== 0 ? secretarioList.map(secretaria => (
                <SecretarioCards
                  key={secretaria.id}
                  secretaria={secretaria as Secretaria}
                  onDelete={() => onDelete(secretaria as Secretaria)}
                  setIsOpen={setIsOpen}
                  setModalIndex={setModalIndex}
                  loader={loader}
                />
              )) :
                <p className="font-black pl-4 pt-4">Você nao possui nenhum secretario.</p>}

            </div>
          </div>
        </div>
      </Loader>
    </Fragment>
  )
}

export default SecretariadoPage;