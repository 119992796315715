import "../Autorizacoes/style.css";
import React, { Fragment, useEffect, useState } from "react";
import Loader from "src/components/Loader";
import { VscSearch } from "react-icons/vsc";
import { axiosApi } from "../../services/axiosInstances";
import CardLista from "../../components/CardLista";
import { AuthState, ChecklistState, Cirurgias, Fornecedores, FornecedoresState, Hospitais, HospitaisState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import ModalChecklist from "src/components/ModalChecklist";
import EmptyContent from "src/components/EmptyContent";
import ModalTimeline from "src/components/ModalTimeline";
import ReactPaginate from "react-paginate";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { setUpdateChecklist } from "src/store/actions/checklist";
import { setUpdateFornecedores } from "src/store/actions/fornecedores";
import { setUpdateHospitais } from "src/store/actions/hospitais";
import Titulos from "src/components/Titulos";
import CardAutorizacao from "src/components/CardAutorizacoes";

const mapStateToProps = ({
  auth,
  cirurgias,
  fornecedores,
  hospitais,
}: {
  auth: AuthState;
  cirurgias: ChecklistState;
  fornecedores: FornecedoresState;
  hospitais: HospitaisState;
}): AuthState & ChecklistState & FornecedoresState & HospitaisState => {
  return {
    doutor: auth.doutor,
    usuario: auth.usuario,
    cirurgias: cirurgias.cirurgias,
    fornecedores: fornecedores.fornecedores,
    hospitais: hospitais?.hospitais,
  };
};

const mapDispatchToProps = {
  setUpdateChecklist, setUpdateFornecedores, setUpdateHospitais,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  thClassName?: string;
  tableClassName?: string;
};

type HomeResponse = {
  cirurgias: Cirurgias[];
  total: number;
};

type FornecedorResponse = {
  fornecedores: Fornecedores[];
  total: number;
};

type HospitalResponse = {
  hospitais: Hospitais[];
  total: number;
};

function AutorizacoesPage({
  thClassName = "px-4 py-2",
  tableClassName = "table-auto table-autorizacoes table-toggler",
  doutor,
  usuario,
  cirurgias,
  setUpdateChecklist,
  setUpdateFornecedores,
  setUpdateHospitais,
}: Props): JSX.Element {
  const [loader, setLoader] = useState(false);
  const [idCirurgia, setIdCirurgia] = useState<number>();
  const [statusCirurgia, setStatusCirurgia] = useState<string>();
  const [open, setOpen] = useState(false);
  const [openTimeLine, setOpenTimeLine] = useState(false);
  const closeTimeline = () => setOpenTimeLine(!openTimeLine);
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [filtro, setFiltro] = useState<string>("");
  const [windowCheck, setWindowCheck] = useState(window.innerWidth > 833);
  const [solicitadas, setSolicitadas] = useState<Cirurgias[]>([]);

  useEffect(() => {
    const handleResize = () => {
      setWindowCheck(window.innerWidth > 833);
    };

    window.addEventListener('resize', handleResize);
    // Remova o listener quando o componente for desmontado
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const loadDados = async () => {
    try {
      if (!filtro) setLoader(true);

      const { data } = await axiosApi.get<HomeResponse>(
        `/autorizacoes?id=${doutor?.id}&limit=${perPage}&offset=${offset}&filtro=${filtro}`
      );

      // const { data: data4 } = await axiosApi.get<HomeResponse>(
      //   `/autorizacoes?id=${doutor?.id}&limit=${''}&offset=${offset}&filtro=${filtro}`
      // );

      setSolicitadas(data.cirurgias);
      const arr = Math.ceil(data.total / perPage);
      setUpdateChecklist(data.cirurgias);
      setPageCount(arr);

      const { data: data2 } = await axiosApi.get<FornecedorResponse>(
        `/list-fornecedores`
      );
      setUpdateFornecedores(data2["ativos"]);

      const { data: data3 } = await axiosApi.get<HospitalResponse>(
        `/list-hospitais`
      );
      setUpdateHospitais(data3["ativos"]);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    loadDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doutor, offset, perPage, filtro]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(event.target.value);
  };

  const loadDadosTable = async () => {
    try {
      const { data } = await axiosApi.get<HomeResponse>(
        `/autorizacoes?id=${doutor?.id}&limit=${perPage}&offset=${offset}&filtro=${filtro}`
      );
      setUpdateChecklist(data.cirurgias);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handlePageChange = (select: { selected: number }) => {
    const selectedPage = select.selected;
    setOffset(selectedPage * perPage);
  };

  const dadosCirurgia = (id: number): void => {
    const cirurgiaStatus = [...cirurgias].find((element) => element.id === id);
    if (cirurgiaStatus?.status.descricao === 'Concluída' && usuario?.nivel === 200) {
      return;
    }
    setIdCirurgia(id);
    setStatusCirurgia(cirurgiaStatus?.status.descricao);
    setOpen(true);
  };

  const timeLine = (id: number): void => {
    setIdCirurgia(id);
    setOpenTimeLine(true);
  };

  const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3 || usuario?.nivel === 2 || usuario?.nivel === 4 || usuario?.nivel === 5 || usuario?.nivel === 6;

  const theFirst = 0;
  const theLast = pageCount - 1;
  const page = Math.ceil(offset / perPage);

  return (
    <Fragment>
      <ModalChecklist
        idCirurgia={idCirurgia}
        open={open}
        setOpen={setOpen}
        updateData={loadDadosTable}
        status={statusCirurgia}
      />

      <ModalTimeline
        idCirurgia={idCirurgia}
        open={openTimeLine}
        close={closeTimeline}
      />

      <Loader isActive={loader}>
        <div className="p-12 p-12-mobile div-autorizacoes background-mobile">
          <Titulos className={`hidden flex justify-center title-mobile pb-2`} titulo="Cirurgias" />
          <div className="mt-20 cirurgias-spacing">
            <div className={`filter-autorizacoes grid grid-cols-6 p-2 px-5 divide-x-2 divide-gray-400 w-6/12 input-search-cirurgias`}>
              <div className="col-span-6 flex pl-5">
                <VscSearch size="1rem" className="mt-3 mr-1 search-icon w-100" />
                <input
                  placeholder="Buscar por nome do Paciente, Cirurgia ou Cirurgião"
                  type="text"
                  className="search-bar border-b-1 px-1 w-full"
                  name="filtro"
                  onChange={onChange}
                  autoComplete="off"
                  value={filtro}
                />
              </div>
            </div>
          </div>

          {nivelCheck ? (
            <div className={`grid grid-cols-full py-16 text-sm p-adjust`}>
              {cirurgias.length === 0 ? (
                <EmptyContent
                  messageSize="2xl"
                  iconSize="2.2rem"
                  paddingContent={"py-32"}
                  titleContent="Sem Novas Autorizações"
                />
              ) : (
                <Fragment>
                  <table className={tableClassName}>
                    <thead>
                      <tr className="text-center">
                        <th className={thClassName}></th>
                        <th className={`text-left pl-3`}>Nome do Paciente</th>
                        <th className={thClassName}>Telefone</th>
                        <th className={thClassName}>WhatsApp</th>
                        <th className={thClassName}>Email</th>
                        <th className={thClassName}>Cirurgia</th>
                        <th className={thClassName}>Data</th>
                        <th className={thClassName}>Cirurgião</th>
                        <th className={thClassName}> </th>
                      </tr>
                    </thead>

                    {cirurgias?.map((lista: Cirurgias) => {
                      const nomeCirurgia: string =
                        lista.cirurgia === null ? "-" : lista.cirurgia;
                      const confirmacao_data: string =
                        lista.confirmacao_data === null
                          ? "-"
                          : lista.confirmacao_data
                            .split("-")
                            .reverse()
                            .join("/");
                      const telefone: string =
                        lista.telefone === null ? "-" : lista.telefone;
                      return (
                        <CardLista
                          onClick={dadosCirurgia}
                          onClickTimeline={timeLine}
                          key={lista.id}
                          id={lista.id}
                          nome={lista.nome}
                          status={lista.status.descricao}
                          telefone={telefone}
                          whatsapp={lista.whatsapp}
                          email={lista.email}
                          nomeCirurgia={nomeCirurgia ? nomeCirurgia : "-"}
                          confirmacao_data={
                            confirmacao_data ? confirmacao_data : "-"
                          }
                          nomeMedico={lista.doutor.nome}
                        />
                      );
                    })}
                  </table>
                  {!windowCheck && 
                    <div className="col-span-1">
                      <Titulos titulo="Novas Solicitações" />

                      {(solicitadas?.length) === 0 ? (
                        <EmptyContent
                          messageSize="xl"
                          iconSize="2rem"
                          paddingContent={
                            usuario?.nivel === 5 ||
                              usuario?.nivel === 1 ||
                              usuario?.nivel === 3
                              ? "py-64"
                              : "py-32"
                          }
                          titleContent="Sem Novas Solicitações"
                        />
                      ) : (
                        <div className="scrollHome overflow-y-auto overflow-x-hidden scroll-box container px-2 py-1">
                          {[...solicitadas]
                            // .slice(0, 30)
                            .map((autorizacoes: Cirurgias) => {
                              const nomeCirurgia: string =
                                autorizacoes?.hospital === null
                                  ? "-"
                                  : autorizacoes?.cirurgia;
                              return (
                                <CardAutorizacao
                                  onClick={dadosCirurgia}
                                  key={autorizacoes.id}
                                  id={autorizacoes.id}
                                  nome={autorizacoes.nome}
                                  doutorNome={autorizacoes.doutor?.nome}
                                  status={autorizacoes.status.descricao}
                                  nomeCirurgia={nomeCirurgia}
                                />
                              );
                            })}
                        </div>
                      )}
                    </div>
                  }

                  {pageCount === 1 ? (
                    ""
                  ) : (
                    <ReactPaginate
                      previousLabel={
                        <BiChevronLeft
                          size="1.5rem"
                          className={
                            offset / perPage === theFirst
                              ? "cursor-default text-gray-400 outline-none"
                              : "text-blue outline-none"
                          }
                        />
                      }
                      nextLabel={
                        <BiChevronRight
                          size="1.5rem"
                          className={
                            offset / perPage === theLast
                              ? "cursor-default text-gray-400 outline-none"
                              : "text-blue outline-none"
                          }
                        />
                      }
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      forcePage={page}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "flex justify-center space-x-3 pt-10 text-md"
                      }
                      activeClassName={"active"}
                      activeLinkClassName={"p-1 transform scale-150 "}
                    />
                  )}
                </Fragment>
              )}
            </div>
          ) : (
            <div className="grid grid-cols-full py-16 text-sm">
              {cirurgias?.length === 0 ? (
                <EmptyContent
                  messageSize="2xl"
                  iconSize="2.2rem"
                  paddingContent={"py-32"}
                  titleContent="Sem Novas Autorizações"
                />
              ) : (
                <Fragment>
                  <table className={tableClassName}>
                    <thead>
                      <tr className="text-center">
                        <th className={thClassName}></th>
                        <th className={`text-left pl-3`}>Cirurgião</th>
                        <th className={thClassName}>Paciente</th>
                        <th className={thClassName}>Cirurgia</th>
                        <th className={thClassName}>Hospital</th>
                        <th className={thClassName}>Data da Cirurgia</th>
                        <th className={thClassName}>Vencimento da Guia</th>
                        <th className={thClassName}>Data Confirmação</th>
                      </tr>
                    </thead>
                    {cirurgias?.map((lista: Cirurgias) => {
                      const nomeCirurgia: string =
                        lista.cirurgia === null ? "-" : lista.cirurgia;
                      const confirmacao_data: string = lista.confirmacao_data
                        ? lista.confirmacao_data.split("-").reverse().join("/")
                        : "-";
                      const dataCirurgia: string =
                        lista.material?.data_confirmacao === null
                          ? "-"
                          : lista.material?.data_confirmacao
                            .split("-")
                            .reverse()
                            .join("/");

                      const vencimento: string = lista.vencimento === null
                        ? "-"
                        : lista.vencimento.split("-").reverse().join("/");
                      return (
                        <CardLista
                          onClick={dadosCirurgia}
                          key={lista.id}
                          id={lista.id}
                          status={lista.status.descricao}
                          nomeMedico={lista.doutor.nome}
                          nome={lista.nome}
                          nomeCirurgia={nomeCirurgia}
                          nomeHospital={
                            lista.hospital?.nome ? lista.hospital.nome : "-"
                          }
                          dataCirurgia={dataCirurgia ? dataCirurgia : "-"}
                          vencimentoGuia={vencimento ? vencimento : "-"}
                          confirmacao_data={
                            confirmacao_data ? confirmacao_data : "-"
                          }
                        />
                      );
                    })}
                  </table>
                  {pageCount === 1 ? (
                    ""
                  ) : (
                    <ReactPaginate
                      previousLabel={
                        <BiChevronLeft
                          size="1.5rem"
                          className={
                            offset / perPage === theFirst
                              ? "cursor-default text-gray-400 outline-none"
                              : "text-blue outline-none"
                          }
                        />
                      }
                      nextLabel={
                        <BiChevronRight
                          size="1.5rem"
                          className={
                            offset / perPage === theLast
                              ? "cursor-default text-gray-400 outline-none"
                              : "text-blue outline-none"
                          }
                        />
                      }
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={pageCount}
                      forcePage={page}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "flex justify-center space-x-3 pt-10 text-md"
                      }
                      activeClassName={"active"}
                      activeLinkClassName={"p-1 transform scale-150 "}
                    />
                  )}
                </Fragment>
              )}
            </div>
          )}
          <div className="col-span-1 div-card-autorizacao">
            {/* <Titulos titulo="Novas Autorizações" />
          
                        {(solicitadas?.length) === 0 ? (
                          <EmptyContent
                            messageSize="xl"
                            iconSize="2rem"
                            paddingContent={
                              usuario?.nivel === 5 ||
                                usuario?.nivel === 1 ||
                                usuario?.nivel === 3
                                ? "py-64"
                                : "py-32"
                            }
                            titleContent="Sem Novas Autorizações"
                          />
                        ) : (
                          <div className="scrollHome overflow-y-auto overflow-x-hidden scroll-box container px-2 py-1">
                            {[...solicitadas]
                              .slice(0, 30)
                              .map((autorizacoes: Cirurgias) => {
                                const nomeCirurgia: string =
                                  autorizacoes?.hospital === null
                                    ? "-"
                                    : autorizacoes?.cirurgia;
                                return (
                                  <CardAutorizacao
                                    onClick={dadosCirurgia}
                                    key={autorizacoes.id}
                                    id={autorizacoes.id}
                                    nome={autorizacoes.nome}
                                    doutorNome={autorizacoes.doutor?.nome}
                                    status={autorizacoes.status.descricao}
                                    nomeCirurgia={nomeCirurgia}
                                  />
                                );
                              })}
                          </div>
                        )} */}
          </div>
        </div>
      </Loader>
    </Fragment>
  );
}

export default connector(AutorizacoesPage);
