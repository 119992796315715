import React, { Fragment, useState, useEffect } from "react";
import "./style.css";
import { BsChevronDown } from "react-icons/bs";
import ButtonFormSubmit from "../Inputs/buttonSubmit";
import { BiCheck, BiCheckbox } from "react-icons/bi";
import { RiCheckboxFill } from "react-icons/ri";
import { axiosApi } from "src/services/axiosInstances";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { AuthState, ChecklistState } from "src/store/types";
import { setUpdateChecklist } from "src/store/actions/checklist";
import { connect, ConnectedProps } from "react-redux";
import Img from "src/assets/info.png";

const mapStateToProps = ({
  auth,
  cirurgias,
}: {
  auth: AuthState;
  cirurgias: ChecklistState;
}): AuthState & ChecklistState => {
  return {
    doutor: auth.doutor,
    usuario: auth.usuario,
    cirurgias: cirurgias.cirurgias,
  };
};

const mapDispatchToProps = {
  setUpdateChecklist,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  idCirurgia?: number;
  accordionTitle?: string;
  actAccordion?: boolean;
  toggleAccordion?: (event: any) => void;
  confirmacao_cirurgia?: boolean;
  confirmacao_hora?: string;
  confirmacao_data?: string | undefined;
  usuario_id?: any;
  etapa_id?: number;
  data_edit?: string;
  materiais?: Array<Material>;
  setDadosModal: any;
  dadosModal: any;
  confirmacao?: any;
  updateData?: any;
  toggleChecklist?: any;
};

type Form = {
  etapa_id?: number;
  confirmacao_hora?: string | undefined;
  confirmacao_data?: string;
  confirmacao_cirurgia?: boolean;
  materiais_secretaria: Array<MaterialSecretaria>;
};

type MaterialSecretaria = {
  id?: number;
  confirmacao_secretaria?: boolean;
};

type Material = {
  fornecedor_id: number;
  descricao: string;
};

type Materiais = {
  id: number;
  checklist_id: number;
  fornecedor_id: number;
  descricao: string;
  reportar_problema: string;
  confirmado: boolean;
  fornecedor: Fornecedor;
  confirmacao_secretaria: boolean;
};

type Fornecedor = {
  id: number;
  razao_social: string;
  usuario_id: number;
};

function Accordion({
  idCirurgia,
  accordionTitle,
  toggleAccordion,
  confirmacao_hora,
  confirmacao_data,
  etapa_id = 3,
  usuario_id,
  data_edit,
  materiais,
  confirmacao,
  actAccordion,
  setDadosModal,
  dadosModal,
  confirmacao_cirurgia,
  updateData,
  toggleChecklist,
  cirurgias,
  usuario,
  setUpdateChecklist,
}: Props): JSX.Element {
  const active = !actAccordion ? "active-accordion" : " ";
  const height = !actAccordion ? "0px" : "100%";
  const display = !actAccordion ? "none" : "block";
  const icon = !actAccordion ? "accordion-icon" : "accordion-icon-rotate";
  const title = !actAccordion ? "black" : "var(--primary-blue-default)";

  const [form, setForm] = useState<Form>({
    etapa_id: etapa_id,
    confirmacao_hora: confirmacao_hora,
    confirmacao_data: confirmacao_data,
    confirmacao_cirurgia: confirmacao_cirurgia,
    materiais_secretaria: [],
  });

  const postForm = (event: any) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onCheckbox = (event: any) => {
    const { name, value, checked } = event.target;
    if (name === "confirmacao_secretaria") {
      let materiaisPrev = form.materiais_secretaria.filter(
        (material) => material.id !== value
      );
      materiaisPrev.push({ id: value, confirmacao_secretaria: checked });
      setForm({
        ...form,
        [name]: checked,
        materiais_secretaria: materiaisPrev,
      });
    } else {
      setForm({
        ...form,
        [name]: checked,
      });
    }
  };

  useEffect(() => {
    if (dadosModal?.materiais.length !== 0 && String(dadosModal?.materiais) !== 'undefined') {
      const materiaisForm = dadosModal.materiais.map(
        (material: MaterialSecretaria) => ({
          id: material.id,
          confirmacao_secretaria: material.confirmacao_secretaria,
        })
      );
      setForm({
        ...form,
        materiais_secretaria: materiaisForm,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosModal.materiais]);

  const findForm = form.materiais_secretaria.find(
    (item) => item.id === idCirurgia
  );

  const confirmForm = !findForm?.confirmacao_secretaria === true;
  1 < 0 && console.log(confirmForm);

  const day = new Date();
  const m = day.getMonth() + 1;
  const month = m < 10 ? "0" + m : "";
  const today = day.getDate() + "/" + month + "/" + day.getFullYear();
  //console.log(form.confirmacao_data === today ? "erro" : "sem erro");

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (form.confirmacao_data === today) {
      toast.error('Data de confirmação incompatível!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      try {
        console.log("confirmacao");
        console.log(form);
        await axiosApi.post(`/checklist/${idCirurgia}`, form);
        setDadosModal({
          ...dadosModal,
          confirmacao_data: form.confirmacao_data,
          confirmacao_hora: form.confirmacao_hora,
          confirmacao_cirurgia: form.confirmacao_cirurgia,
          materiais_secretaria: form.materiais_secretaria,
        });
        if (
          (confirmacao_data && confirmacao_hora) ||
          confirmacao_cirurgia === true
        ) {
          toast.success('Agendamento Realizado!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          toggleChecklist();
          updateData();
        } else if (
          (confirmacao_hora && confirmacao_data) ||
          confirmacao_cirurgia === false
        ) {
          toast.warn('Agendado com o Hospital! Prosseguir com fornecedor e paciente.', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          toggleChecklist();
          updateData();
        }
      } catch (error: any) {
        if (error) {
          toast.error('Data de confirmação incompatível!', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const hospitalCheck = usuario?.nivel === 6;

  return (
    <Fragment>
      <div className="accordion-section py-3">
        <div
          className={`accordion-btn text-left ${active}`}
          onClick={toggleAccordion}
        >
          <div className="flex justify-between cursor-pointer p-3">
            <div className="rounded-full p-1 border-gray-400 border-2">
              <BiCheck size="1rem" className="text-white" />
            </div>
            <p
              className="accordion-title font-bold xl:text-base md:text-sm sm:text-sm"
              style={{ color: `${title}`, transition: "0.5s" }}
            >
              {accordionTitle}
            </p>
            <BsChevronDown className={`mt-1 font-bold ${icon}`} />
          </div>
        </div>
        <div
          className="accordion-content"
          style={{ height: `${height}`, display: `${display}` }}
        >
          <div className="mobile-accordion-agendamento-container container p-2">
            <form onSubmit={handleSubmit} className="py-2">
              <div className="px-3 py-2">
                <p className="text-base font-semibold text-black mb-1">
                  Confirmação com o Hospital
                </p>
                <p className="text-sm text-black mb-1">
                  Qual a data da Cirurgia?
                </p>
                <InputMask
                  mask="99/99/9999"
                  onChange={postForm}
                  defaultValue={confirmacao_data}
                  disabled={hospitalCheck}
                >
                  <input
                    name="confirmacao_data"
                    type="text"
                    className="input-modal text-sm w-full mb-2 border-solid border-b-2 border-gray-400"
                    required={true}
                  />
                </InputMask>

                <p className="text-sm text-black mb-1">
                  Qual a hora da Cirurgia?
                </p>
                <InputMask
                  mask="99:99"
                  onChange={postForm}
                  defaultValue={confirmacao_hora}
                  disabled={hospitalCheck}
                >
                  <input
                    name="confirmacao_hora"
                    type="text"
                    className="input-modal text-sm w-full border-solid border-b-2 border-gray-400"
                    required={true}
                  />
                </InputMask>


                {hospitalCheck ? (<Fragment></Fragment>) : (
                  <div className="my-3">
                    <div className="flex rounded-full shadow-md px-2">
                      <img src={Img} alt="" className="w-8 h-8" />
                      <span className="font-bold text-xs text-gray-800">
                        Após confirmação do hospital, prosseguir para fornecedor e
                        paciente
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div className="px-3 py-2">
                {!confirmacao_data ||
                  !confirmacao_hora ||
                  usuario_id === null ? (
                  ""
                ) : (
                  <Fragment>
                    <p className="text-base font-semibold text-black mb-1">
                      Confirmação com o Fornecedor
                    </p>
                    {dadosModal?.materiais.map((material: Materiais) => {
                      if (material.descricao != null) {
                        return (
                          <Fragment key={material.id}>
                            <div className="flex">
                              {material.confirmado === true ? (
                                <RiCheckboxFill
                                  className={`text-blue mr-1 rounded-lg mt-1`}
                                  size="1.3rem"
                                />
                              ) : (
                                <BiCheckbox
                                  className={`text-gray-500 mr-1`}
                                  size="1.3rem"
                                />
                              )}
                              <div className="w-full flex">
                                <p className="text-black font-light text-sm break-words">
                                  Grupo de Materiais de{" "}
                                  <span className="font-semibold">
                                    {material.fornecedor?.razao_social}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <p className="text-xs text-gray-600 font-light break-words">
                              {material.descricao}
                            </p>
                            {!confirmacao_data ||
                              !confirmacao_hora ||
                              usuario_id === null ? (
                              ""
                            ) : (
                              <p className="text-xs text-gray-600 mb-1 flex align-middle">
                                <input
                                  onChange={onCheckbox}
                                  type="checkbox"
                                  value={material.id}
                                  required={true}
                                  disabled={hospitalCheck}
                                  defaultChecked={
                                    material.confirmacao_secretaria === true
                                      ? true
                                      : false
                                  }
                                  name="confirmacao_secretaria"
                                  className="mt-1 mr-1"
                                />
                                <span className="break-words text-gray-800">
                                  Confirmação com{" "}
                                  <span className="font-bold">
                                    {material.fornecedor?.razao_social}
                                  </span>
                                </span>
                              </p>
                            )}
                          </Fragment>
                        );
                      }
                      return null;
                    })}
                  </Fragment>
                )}
              </div>

              <div>
                {usuario_id === null && confirmacao_data && confirmacao_hora ? (
                  <Fragment>
                    {dadosModal?.materiais.map((material: Materiais) => {
                      if (material.descricao != null) {
                        return (
                          <Fragment key={material.id}>
                            <div>
                              <div>
                                <p className="text-base font-semibold text-black mb-1">
                                  Confirmação com o Fornecedor
                                </p>
                                <p className="text-xs text-gray-600 mb-1 flex align-middle">
                                  <input
                                    onChange={onCheckbox}
                                    type="checkbox"
                                    value={material.id}
                                    required={true}
                                    disabled={hospitalCheck}
                                    defaultChecked={
                                      material.confirmacao_secretaria === true
                                        ? true
                                        : false
                                    }
                                    name="confirmacao_secretaria"
                                    className="mt-1 mr-1"
                                  />
                                  <span className="break-words text-gray-800">
                                    Confirmação com{" "}
                                    <span className="font-bold">
                                      {material.fornecedor?.razao_social}
                                    </span>
                                  </span>
                                </p>
                                <p className="text-xs text-gray-600 font-light break-words">
                                  {material.descricao}
                                </p>
                              </div>
                            </div>
                          </Fragment>
                        );
                      }
                      return null;
                    })}
                    {confirmacao_data !== undefined ||
                      confirmacao_hora !== null ? (
                      <div className="mt-3">
                        <p className="text-base font-semibold text-black mb-1">
                          Agendamento FINAL do Paciente
                        </p>
                        <p className="text-xs text-gray-600 mb-1 flex align-middle">
                          <input
                            onChange={onCheckbox}
                            type="checkbox"
                            required={true}
                            defaultChecked={
                              confirmacao_cirurgia === true ? true : false
                            }
                            disabled={hospitalCheck}
                            name="confirmacao_cirurgia"
                            className="mt-1 mr-1"
                          />
                          <span className="break-words text-gray-800">
                            Confirmação para o dia {confirmacao_data} às{" "}
                            {confirmacao_hora}
                          </span>
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </Fragment>
                ) : (
                  ""
                )}
              </div>

              <div className="px-3 py-2">
                {confirmacao_data === undefined ||
                  confirmacao_hora === null ||
                  usuario_id === null ? (
                  ""
                ) : (
                  <Fragment>
                    <p className="text-base font-semibold text-black mb-1">
                      Agendamento FINAL do Paciente
                    </p>
                    <p className="text-xs text-gray-600 mb-1 flex align-middle">
                      <input
                        onChange={onCheckbox}
                        type="checkbox"
                        required={true}
                        disabled={hospitalCheck}
                        defaultChecked={
                          confirmacao_cirurgia === true ? true : false
                        }
                        name="confirmacao_cirurgia"
                        className="mt-1 mr-1"
                      />
                      <span className="break-words text-gray-800">
                        Confirmação para o dia {confirmacao_data} às{" "}
                        {confirmacao_hora}
                      </span>
                    </p>
                  </Fragment>
                )}
                {hospitalCheck ? (<Fragment></Fragment>) : (<ButtonFormSubmit name="btn-submit" type="submit" />)}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default connector(Accordion);
