import React from "react"
import Relatorio from "../interface";
import useClickOutside from "../useClickOutside";

const HospitalForm: React.FC<Relatorio> = ({
  handleDataInicialChange,
  handleDataFinalChange,
  setDescritivo,
  handleDoutorInputChange,
  setShowDoutorDropdown,
  doutorInputValue,
  showDoutorDropdown,
  filteredDoutorOptions,
  doutorDropdownRef,
  dropdownHospitalRef,
  setHospitalInputValue,
  setHospitalSuggestions,
  setHospitalId,
  setShowHospitalDropdown,
  setDoutorInputValue,
  setDoutorId,
  handleDoutorSuggestionClick,
  descritivo,
  setFornecedorInputValue,
  fornecedorInputValue,
  fornecedorDropdownRef,
  filteredFornecedorOptions,
  showFornecedorDropdown,
  handleFornecedorInputChange,
  setShowFornecedorDropdown,
  handleFornecedorSuggestionClick,
  setFornecedorId,
}) => {

  useClickOutside(dropdownHospitalRef, () => {
    setHospitalInputValue('');
    setHospitalSuggestions([]);
    setHospitalId(null);
    setShowHospitalDropdown(false);
  });

  useClickOutside(doutorDropdownRef, () => {
    setDoutorInputValue('');
    setDoutorId(null);
    setShowDoutorDropdown(false);
  });

  useClickOutside(fornecedorDropdownRef, () => {
    setFornecedorInputValue('');
    setFornecedorId(null);
    setShowFornecedorDropdown(false);
  });

  return (

    <>
      <div className="flex flex-col mt-5 gap-y-5">
        <div className="flex items-center justify-between" style={{ maxWidth: 410 }}>
          <div className="flex flex-col items-center">
            <label className="text-black pb-2" htmlFor="data-inicial">Data inicial*</label>
            <input
              id="data-inicial"
              style={{ maxWidth: 195 }}
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border rounded-xl"
              type="date"
              onChange={handleDataInicialChange}
              required
            />
          </div>
          <div className="flex flex-col items-center">
            <label className="text-black pb-2" htmlFor="data-final">Data final*</label>
            <input
              id="data-final"
              style={{ maxWidth: 195 }}
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border rounded-xl"
              type="date"
              onChange={handleDataFinalChange}
              required
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label className="text-black" htmlFor="descritivo">Cirurgia</label>
          <input
            id="descritivo"
            style={{ maxWidth: 410 }}
            className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border rounded-xl"
            type="text"
            onChange={(e) => setDescritivo(e.target.value)}
            value={descritivo || ''}
          />
        </div>

        <div className="flex flex-col TypeAheadDropDown">
          <label htmlFor="cirurgiao" className="text-black">Cirurgião</label>
          <input
            style={{ maxWidth: 410 }}
            className="TypeAheadDropDown font-lato placeholder-gray-600 h-10 border rounded-xl"
            onChange={handleDoutorInputChange}
            type="text"
            id="cirurgiao"
            onFocus={() => setShowDoutorDropdown(true)}
            value={doutorInputValue || ''}
          />
          {showDoutorDropdown && filteredDoutorOptions.length > 0 && (
            <ul
              ref={doutorDropdownRef}
              className="TypeAheadDropDown" style={{ maxWidth: 410 }}>
              {filteredDoutorOptions.map((suggestion: any) => (
                <li
                  className="TypeAheadDropDown"
                  key={suggestion.id}
                  onClick={() => handleDoutorSuggestionClick(suggestion)}>
                  {suggestion.nome}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="flex flex-col TypeAheadDropDown">
          <label htmlFor="fornecedor" className="text-black">Fornecedor</label>
          <input
            style={{ maxWidth: 410 }}
            className="TypeAheadDropDown font-lato placeholder-gray-600 h-10 border rounded-xl"
            onChange={handleFornecedorInputChange}
            type="text"
            id="fornecedor"
            onFocus={() => setShowFornecedorDropdown(true)}
            value={fornecedorInputValue || ''}
          />
          {showFornecedorDropdown && filteredFornecedorOptions.length > 0 && (
            <ul
              ref={fornecedorDropdownRef}
              className="TypeAheadDropDown" style={{ maxWidth: 410 }}>
              {filteredFornecedorOptions.map((suggestion: any) => (
                <li
                  className="TypeAheadDropDown"
                  key={suggestion.id}
                  onClick={() => handleFornecedorSuggestionClick(suggestion)}>
                  {suggestion.razao_social}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  )
}

export default HospitalForm;