import React, { Fragment } from "react";
import "./style.css";
import Popup from "reactjs-popup";
import { AiOutlineInfoCircle } from "react-icons/ai";
// import { axiosApi } from "src/services/axiosInstances";
import Loader from "../Loader";

type Props = {
  open?: any;
  close?: any;
  idCirurgia?: number;
  descricao?: string;
  reportar_problema?: string;
  checklist_id?: any;
  usuario_id?: any;
  created_at?: any;
  loader?: any;
};

function ModalReportado({
  open,
  close,
  idCirurgia,
  usuario_id,
  checklist_id,
  loader,
  created_at,
  descricao,
  reportar_problema,
}: Props): JSX.Element {
  const modalWidth = { width: "40%" };

  const formatDate = new Date(`${created_at}`).toLocaleDateString();
  const formatHour = new Date(`${created_at}`).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <Popup
      open={open}
      closeOnDocumentClick={true}
      onClose={close}
      contentStyle={modalWidth}
      modal
      nested={true}
    >
      <Fragment>
        <div className="bg-white modal-new py-5 px-10">
          <Loader isActive={loader} hide="hidden">
            <div className="pt-5 pb-3 text-left text-xl font-bold">
              Problema Reportado Pelo Fornecedor BonefixImplates Ortopédicos
              {descricao}
              <div className="content">
                <div className="flex">
                  <p className="break-words text-base font-light py-5">
                    {reportar_problema}
                  </p>
                </div>
                <div className="flex justify-start">
                  <h2 className="mb-3 text-base font-bold text-red-600 mr-2">
                    {formatDate + " - " + formatHour}
                  </h2>
                  <AiOutlineInfoCircle
                    size="1.4rem"
                    className="text-red-600"
                  />
                </div>
              </div>
            </div>
          </Loader>
        </div>
      </Fragment>
    </Popup>
  );
}
export default ModalReportado;