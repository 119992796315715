import React, { useEffect, useState } from "react";
import "./style.css"
import NavBarEquipeMedica from "./NavBarEquipeMedica";
import { axiosApi } from "src/services/axiosInstances";
import ListaEquipeMedica from "./ListaEquipeMedica";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Titulos from "src/components/Titulos";

interface Usuario {
  id: number;
}

interface Doutor {
  id: number;
  nome: string;
  cidade: string | null;
  uf: string | null;
  usuario_id: number;
  foto?: string;
  usuario: Usuario;
}

export interface Solicitacao {
  id: string;
  doutor_requisitante_id: number;
  doutor_requisitado_id: number;
  solicitacao_status_id: number;
  created_at: string;
  updated_at: string;
  doutor_requisitado: Doutor;
  doutor_requisitante: Doutor;
}

export interface MedicosData {
  minhaEquipe: Solicitacao[];
  participacoes: Solicitacao[];
  solicitacoes: Solicitacao[];
}


const EquipeMedica: React.FC = () => {

  const [medicosData, setMedicosData] = useState<MedicosData>({
    minhaEquipe: [],
    participacoes: [],
    solicitacoes: []
  });
  const [indexNavBar, setIndexNavBar] = useState(0)
  const [loader, setLoader] = useState(false);

  const fetchMedicosData = async () => {
    try {
      setLoader(true)
      const { data } = await axiosApi.get(`/equipe`);
      setMedicosData(data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchMedicosData()
  }, []);

  return (
    <div className="p-12 background-mobile p-12-mobile">
      <Titulos className={`hidden flex justify-center title-mobile pt-0`} titulo="Equipe Médica" />
      <NavBarEquipeMedica medicosData={medicosData} indexNavBar={indexNavBar} setIndexNavBar={setIndexNavBar} fetchMedicosData={fetchMedicosData} />
      <Routes>
        <Route path='/' element={<ListaEquipeMedica medicosData={medicosData} indexNavBar={indexNavBar} fetchMedicosData={fetchMedicosData} loader={loader} />} />
        {/* <Route path='participacoes' element={<ListaEquipeMedica medicosData={medicosData} indexNavBar={1} fetchMedicosData={fetchMedicosData} />}/>
      <Route path='solicitacoes'  element={<ListaEquipeMedica medicosData={medicosData} indexNavBar={2} fetchMedicosData={fetchMedicosData} />}/> */}
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default EquipeMedica;