import React from "react";
import "./style.css";
import trashIcon from "../../assets/trash.png"
import letterIcon from "../../assets/email-svgrepo-com 1.png"

interface Fotos {
  url: string;
}

interface Usuario {
  fotos: Fotos;
  email: string;
}

interface Secretaria {
  id: number;
  email_secretaria: string;
  secretarias: {
    nome: string;
    usuario: Usuario;
  };

}

interface SecretariaCardProps {
  secretaria: Secretaria;
  onDelete: (secretaria: Secretaria) => void;
  setIsOpen: (open: boolean) => void;
  setModalIndex: (index: number) => void;
  loader: boolean;
}

const SecretarioCards: React.FC<SecretariaCardProps> = ({ secretaria, setIsOpen, setModalIndex, onDelete, loader }) => {
  return (
    <div
      style={{ maxWidth: 400 }}
      className="flex items-center justify-between pt-5 pl-5">
      <div className="flex items-center">
        {
          secretaria.secretarias?.usuario.fotos ? <img
            src={secretaria.secretarias && secretaria.secretarias.usuario?.fotos.url}
            alt={secretaria.secretarias && secretaria.secretarias.nome}
            className="flex w-12 h-12 rounded-full object-cover mr-4 secretario-img"
          /> :
            <svg stroke="currentColor" fill="currentColor" viewBox="0 0 496 512" className="text-gray-400 mr-4" height="48px" width="48px" xmlns="http://www.w3.org/2000/svg">
              <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"></path>
            </svg>
        }
        <div>
          <h2 style={{ color: secretaria.secretarias ? '#272848' : 'orange' }} className="font-bold secretario-text">{secretaria.secretarias ? secretaria.secretarias.nome : 'Convite Enviado'}</h2>
          <div className="flex items-center">
            <img
              src={letterIcon}
              alt="letter"
              className="w-5 h-5 letter-icon"
            />
            <p style={{ color: '#272848' }} className="pl-1 pb-1 secretario-text">{secretaria.secretarias ? secretaria.secretarias.usuario.email : secretaria.email_secretaria}</p>

          </div>
        </div>
      </div>
      <button
        onClick={() => {
          setModalIndex(3);
          setIsOpen(true);
          onDelete(secretaria)
        }
        }
        className="text-red-500 hover:text-red-700"
      >
        <img
          src={trashIcon}
          alt="excluir"
          className="trash-button w-9 h-9 mr-1"
        />
      </button>
    </div>
  );
};

export default SecretarioCards;