import React, { FormEvent } from "react"

interface ButtonGerarRelatorio {
  handleSubmit?: (event: FormEvent<HTMLFormElement>) => void;
}

const ButtonGerarRelatorio: React.FC<ButtonGerarRelatorio> = ({ handleSubmit }) => {

  return (

    <>
      <div
        style={{ maxWidth: 410 }}
        className="flex py-10 justify-end">
        <button
          // style={{ width: 160 }}
          className="bg-blue hover:bg-blue-700 text-white py-1 px-3 font-lato font-black rounded-full adicionar-btn"
          onClick={() => handleSubmit}
        >
          Gerar Relatório
        </button>
      </div>
    </>
  )
}



export default ButtonGerarRelatorio;