import React from "react";
import useClickOutside from "../useClickOutside";
import Relatorio from "../interface";

const DoutorForm: React.FC<Relatorio> = ({
  setLoader,
  dataInicial,
  setDataInicial,
  dataFinal,
  setDataFinal,
  descritivo,
  setDescritivo,
  handleDataInicialChange,
  handleDataFinalChange,
  hospitalInputValue,
  setHospitalInputValue,
  hospitalId,
  setHospitalId,
  hospitalSuggestions,
  handleHospitalInputChange,
  handleSuggestionHospitalClick,
  setHospitalSuggestions,
  setParticipacaoId,
  setShowHospitalDropdown,
  showHospitalDropdown,
  dropdownHospitalRef,
  handleParticipacaoInputChange,
  participacaoInputValue,
  participacaoOptions,
  handleParticipacaoSuggestionClick,
  setFilteredOptions,
  setShowParticipacaoDropdown,
  showParticipacaoDropdown,
  dropdownParticipacaoRef,
  filteredOptions,
  setParticipacaoInputValue,
  handleFornecedorInputChange,
  setShowFornecedorDropdown,
  fornecedorInputValue,
  showFornecedorDropdown,
  handleFornecedorSuggestionClick,
  fornecedorDropdownRef,
  filteredFornecedorOptions,
  setFornecedorInputValue,
  setFornecedorId
}) => {

  useClickOutside(dropdownHospitalRef, () => {
    setHospitalInputValue('');
    setHospitalSuggestions([]);
    setHospitalId(null);
    setShowHospitalDropdown(false);
  });

  useClickOutside(dropdownParticipacaoRef, () => {
    setShowParticipacaoDropdown(false);
    setParticipacaoInputValue('');
    setParticipacaoId(null);
  });

  useClickOutside(fornecedorDropdownRef, () => {
    setFornecedorInputValue('');
    setFornecedorId(null);
    setShowFornecedorDropdown(false);
  });


  return (
    <>
      <div className="flex flex-col mt-5 gap-y-5">
        <div style={{ maxWidth: 410 }} className="flex justify-between">
          <div className="flex flex-col items-center">
            <label className="text-black pb-2" htmlFor="data-inicial">Data inicial*</label>
            <input
              id="data-inicial"
              style={{ maxWidth: 195 }}
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border rounded-xl"
              type="date"
              onChange={handleDataInicialChange}
              required
            />
          </div>
          <div className="flex flex-col items-center">
            <label className="text-black pb-2" htmlFor="data-final">Data final*</label>
            <input
              id="data-final"
              style={{ maxWidth: 195 }}
              className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border rounded-xl"
              type="date"
              onChange={handleDataFinalChange}
              required
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label className="text-black" htmlFor="descritivo">Cirurgia</label>
          <input
            id="descritivo"
            style={{ maxWidth: 410 }}
            className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border rounded-xl"
            type="text"
            onChange={(e) => setDescritivo(e.target.value)}
            value={descritivo || ''}
          />
        </div>

        <div className="flex flex-col TypeAheadDropDown">
          <label htmlFor="hospital_nome" className="text-black">Hospital</label>
          <input
            style={{ maxWidth: 410 }}
            className="TypeAheadDropDown font-lato placeholder-gray-600 h-10 border rounded-xl"
            onChange={handleHospitalInputChange}
            type="text"
            id="hospital_nome"
            onFocus={() => setShowHospitalDropdown(true)}
            value={hospitalInputValue}
          />
          {showHospitalDropdown && hospitalSuggestions.length !== 0 && (
            <ul ref={dropdownHospitalRef} className="TypeAheadDropDown" style={{ maxWidth: 410 }}>
              {hospitalSuggestions.map((suggestion) => (
                <li
                  className="TypeAheadDropDown"
                  key={suggestion.id}
                  onClick={() => handleSuggestionHospitalClick(suggestion)}>
                  {suggestion.nome}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="flex flex-col TypeAheadDropDown">
          <label htmlFor="participacao" className="text-black">Participação</label>
          <input
            id="participacao"
            style={{ maxWidth: 410 }}
            className="text-lg pl-2 font-lato placeholder-gray-600 h-10 border rounded-xl"
            placeholder=""
            type="text"
            onChange={handleParticipacaoInputChange}
            value={participacaoInputValue}
            onFocus={() => {
              setFilteredOptions(participacaoOptions)
              setShowParticipacaoDropdown(true)
            }
            }
          />
          {showParticipacaoDropdown && (
            <ul ref={dropdownParticipacaoRef} className="TypeAheadDropDown" style={{ maxWidth: 410 }}>
              {filteredOptions.map((option) => (
                <li
                  key={option.id}
                  onClick={() => handleParticipacaoSuggestionClick(option)}
                  className="TypeAheadDropDown">
                  {option.nome}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="flex flex-col TypeAheadDropDown">
          <label htmlFor="fornecedor" className="text-black">Fornecedor</label>
          <input
            style={{ maxWidth: 410 }}
            className="TypeAheadDropDown font-lato placeholder-gray-600 h-10 border rounded-xl"
            onChange={handleFornecedorInputChange}
            type="text"
            id="fornecedor"
            onFocus={() => setShowFornecedorDropdown(true)}
            value={fornecedorInputValue || ''}
          />
          {showFornecedorDropdown && filteredFornecedorOptions.length > 0 && (
            <ul
              ref={fornecedorDropdownRef}
              className="TypeAheadDropDown" style={{ maxWidth: 410 }}>
              {filteredFornecedorOptions.map((suggestion: any) => (
                <li
                  className="TypeAheadDropDown"
                  key={suggestion.id}
                  onClick={() => handleFornecedorSuggestionClick(suggestion)}>
                  {suggestion.razao_social}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default DoutorForm;
