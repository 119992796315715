import React, { useEffect, useState } from "react";
import "./style.css";
import { BsChevronDown } from "react-icons/bs";
import { BiCheck } from "react-icons/bi";
import { axiosApi } from "src/services/axiosInstances";
import InputMask from "react-input-mask";
import { AuthState, ChecklistState, Fornecedores, FornecedoresState, Hospitais, HospitaisState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import { setUpdateChecklist } from "src/store/actions/checklist";
import TypeaheadDropdown from "../Inputs/typeAheadDropdown";
import { setUpdateFornecedores } from "src/store/actions/fornecedores";
import { setUpdateHospitais } from "src/store/actions/hospitais";
import { FiPlus } from "react-icons/fi";
import { FaChevronDown } from "react-icons/fa";
import { toast } from "react-toastify";
import { CgTrashEmpty } from "react-icons/cg";

const mapStateToProps = ({
  auth,
  cirurgias,
  fornecedores,
  hospitais,
}: {
  auth: AuthState;
  cirurgias: ChecklistState;
  fornecedores: FornecedoresState;
  hospitais: HospitaisState;
}): AuthState & ChecklistState & FornecedoresState & HospitaisState => {
  return {
    doutor: auth.doutor,
    usuario: auth.usuario,
    cirurgias: cirurgias.cirurgias,
    fornecedores: fornecedores.fornecedores,
    hospitais: hospitais?.hospitais,
  };
};

const mapDispatchToProps = {
  setUpdateChecklist, setUpdateFornecedores, setUpdateHospitais,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  accordionTitle?: string;
  actAccordion?: boolean;
  toggleAccordion?: (event: any) => void;
  status?: string;
  idCirurgia?: number;
  updateTable?: any;
  updateData?: any;
  mobile?: boolean;
  setLoader?: any;
};

type Checklist = {
  id: number;
  status: Status;
  hospital: Hospital | string;
  doutor: Doutor;
  nome: string;
  cirurgia: string;
  materiais: Array<Material>;
  materiais_fornecedor: Array<Material>;
  materiais_hospitalares: string;
  vencimento: string;
  datas: Array<Datas>;
  nascimento: string;
  sexo: string;
  telefone: string;
  whatsapp: string;
  email: string;
  pre_operatorio: boolean;
  alergia: boolean;
};

type Status = {
  id: number;
  descricao: string;
};

type Hospital = {
  id: number;
  nome: string;
};

type Doutor = {
  id: number;
  nome: string;
};

type Material = {
  descricao: string;
  fornecedor: Fornecedores | string;
  confirmado: boolean;
};

type Datas = {
  cirurgia_id: number;
  data: string;
};

// type HomeResponse = {
//   checklist: Checklist;
// };

function Accordion({
  idCirurgia,
  accordionTitle,
  toggleAccordion,
  actAccordion,
  updateTable,
  updateData,
  mobile,
  usuario,
  cirurgias,
  fornecedores,
  hospitais,
  setLoader,
}: Props): JSX.Element {
  const active = !actAccordion ? "active-accordion" : " ";
  const height = !actAccordion ? "0px" : "100%";
  const display = !actAccordion ? "none" : "block";
  const icon = !actAccordion ? "accordion-icon" : "accordion-icon-rotate";
  const title = !actAccordion ? "black" : "var(--primary-blue-default)";
  const [dadosModal, setDadosModal] = useState<Checklist>();
  const [isDateValid, setIsDateValid] = useState(true);
  const hospitalCheck = usuario?.nivel === 6;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDados = async () => {
    try {
      const { data } = await axiosApi.get<Checklist>(
        `/checklist/${idCirurgia}`
      );

      data.materiais_fornecedor = data.materiais ?? [];

      if (data.materiais_fornecedor && data.materiais_fornecedor.length !== 0) {
        for (let i = 0; i < data.materiais.length; i++) {
          const fornecedor = data.materiais[i].fornecedor as Fornecedores;
          console.log(fornecedor)
          data.materiais_fornecedor[i].fornecedor = fornecedor.razao_social as string;
        }
      }

      const hospital = (data.hospital as Hospital)?.nome;
      data.hospital = hospital;

      setDadosModal(data);
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (idCirurgia) {
      loadDados();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCirurgia, updateTable]);

  const addGrupoMaterial = (): void => {
    if (dadosModal && dadosModal?.materiais_fornecedor?.length == 3) return;
    setDadosModal({ ...dadosModal!, materiais_fornecedor: [...dadosModal!.materiais_fornecedor, { descricao: "", fornecedor: "", confirmado: false }] });
  };

  const delGrupoMaterial = (index: number) => () => {
    if (dadosModal && dadosModal?.materiais_fornecedor?.length == 0) return;
    const newMateriais = dadosModal?.materiais_fornecedor.filter((material, i) => i !== index);
    setDadosModal({ ...dadosModal!, materiais_fornecedor: newMateriais! });
  }

  const salvarEspecificacoes = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoader(true);
    const formattedDate = dadosModal?.vencimento?.split("/").reverse().join("-")

    const response = await axiosApi.post(`especificacoes-tecnicas/${dadosModal?.id}`, {
      ...dadosModal,
      vencimento: formattedDate,
    });

    if (response.status === 200) {
      toast.success('Especificação salva', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      updateTable();
      updateData();
    } else {
      toast.error('Falha ao salvar especificação', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    setLoader(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name === "vencimento") {
      validateDate(event);
    }

    setDadosModal((dadosModal) => ({
      ...dadosModal!,
      [name]: value,
    }));
  };

  const handleDescricaoMaterialInputChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    console.log(event.target.value)

    const newMateriais = dadosModal?.materiais_fornecedor.map((material, i) => {
      if (i !== index) {
        return material;
      }
      return { ...material, "descricao": value };
    });

    setDadosModal((dadosModal) => ({
      ...dadosModal!,
      "materiais_fornecedor": newMateriais!,
    }));
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setDadosModal((dadosModal) => ({
      ...dadosModal!,
      [name]: value,
    }));
  };

  const handleTypeaheadDropdownChange = (name: string, value: string, index?: number) => {
    if (name === "fornecedor") {
      let materiais = dadosModal?.materiais_fornecedor;

      for (let i = 0; i < materiais!.length; i++) {
        if (i === index) {
          materiais![i].fornecedor = value;
        }
      }
      setDadosModal((dadosModal) => ({
        ...dadosModal!,
        materiais_fornecedor: materiais!,
      }));
    } else {
      setDadosModal((dadosModal) => ({
        ...dadosModal!,
        [name]: value,
      }));
    }
  };

  // Function to handle date change
  const validateDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const [day, month, year] = value.split("/").map(Number);
    const inputDate = new Date(year, month - 1, day);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    setIsDateValid(inputDate >= today);
  };

  const combinedAcess = dadosModal?.status.descricao !== "Autorizada" && dadosModal?.status.descricao !== "Reportado"

  return (
    <>
      <div className="accordion-section py-3">
        <div
          className={`accordion-btn text-left ${active}`}
          onClick={toggleAccordion}
        >
          {dadosModal?.status?.id !== 1 ? (
            <div className="flex justify-between cursor-pointer p-3">
              <div className="rounded-full p-1 bg-blue border-gray-400 border-2">
                <BiCheck size="1rem" className="text-white" />
              </div>

              <p
                className="accordion-title font-bold xl:text-base md:text-sm sm:text-sm"
                style={{ color: `${title}`, transition: "0.5s" }}
              >
                {accordionTitle}
              </p>
              <BsChevronDown size="1rem" className={`mt-1 ${icon}`} />
            </div>
          ) : (
            <div className="accordion-btn rounded-lg text-left text-gray-300 text-md font-bold">
              <div className="flex justify-between cursor-pointer p-3">
                <div className="rounded-full p-1 bg-white border-gray-300 border-2">
                  <BiCheck size="1rem" className="text-white" />
                </div>
                <p className="disabled-accordion-title font-bold xl:text-base md:text-sm sm:text-sm">
                  {accordionTitle}
                </p>

                <FaChevronDown size="1rem" className={`mt-1 `} />
              </div>
            </div>
          )}
        </div>
        <div
          className="accordion-content"
          style={{ height: `${height}`, display: `${display}` }}
        >
          <div className={`${mobile ? 'mobile-especificacoes-accordion-content-container ' : ''}container p-2`}>
            <form className="py-2" onSubmit={salvarEspecificacoes}>
              <div className="px-8 py-2">
                <p className="text-base font-semibold text-black mb-1">
                  Cirurgia
                </p>
                <input
                  name="cirurgia"
                  type="text"
                  className="input-modal text-sm w-full mb-2 border-solid border-b-2 border-gray-400"
                  required={true}
                  value={dadosModal?.cirurgia}
                  onChange={handleInputChange}
                  disabled={hospitalCheck || (dadosModal?.status.descricao !== "Pendentes" && combinedAcess)}
                />
              </div>
              <div className="px-8 py-2">
                <p className="text-base font-semibold text-black">
                  Materiais
                </p>
                {dadosModal?.materiais_fornecedor?.map((material: Material, index: number) => {
                  return (
                    <>
                      <div className="py-1 flex justify-center items-center">
                        <p className="text-black text-sm pr-2">
                          Grupo de Materiais {index + 1}
                        </p>
                        <button
                          type="button"
                          onClick={delGrupoMaterial(index)}
                          disabled={hospitalCheck || (dadosModal?.status.descricao !== "Pendentes" && combinedAcess)}
                        >
                          <CgTrashEmpty size="1rem" className="mr-2" color="red" />
                        </button>
                      </div>
                      <p className="text-xs">
                        <span className="font-bold mr-1 break-words">
                          Fornecedor:
                        </span>
                        <TypeaheadDropdown
                          index={index}
                          value={material.fornecedor as string}
                          options={fornecedores.map((fornecedor: Fornecedores) => ({
                            "id": fornecedor.id,
                            "nome": fornecedor.fantasia
                          }))}
                          onChange={(value: string) => handleTypeaheadDropdownChange("fornecedor", value, index)}
                          required={true}
                          disabled={hospitalCheck || (dadosModal?.status.descricao !== "Pendentes" && combinedAcess)}
                        />
                      </p>
                      <p className="text-xs">
                        <span className="font-bold mr-1 break-words">
                          Material:
                        </span>
                        <input
                          name="materiais"
                          type="text"
                          className="input-modal text-sm w-full mb-2 border-solid border-b-2 border-gray-400"
                          value={material.descricao}
                          onChange={handleDescricaoMaterialInputChange(index)}
                          required={true}
                          disabled={hospitalCheck || (dadosModal?.status.descricao !== "Pendentes" && combinedAcess)}
                        />
                      </p>
                    </>
                  );
                })}
                <div className="flex justify-center">
                  <button
                    type="button"
                    className={`btn-new-vendedor text-xs font-bold rounded-full content-center px-2 py-1 flex mt-1 shadow-md ${dadosModal?.materiais_fornecedor?.length === 3 || hospitalCheck || (dadosModal?.status.descricao !== "Pendentes" && combinedAcess) ? 'bg-gray-400 text-gray-600' : 'bg-blue text-white'}`}
                    onClick={addGrupoMaterial}
                    disabled={dadosModal?.materiais_fornecedor?.length === 3 || hospitalCheck || (dadosModal?.status.descricao !== "Pendentes" && combinedAcess)}
                  >
                    <FiPlus size="1rem" className="mr-2 text-white" /> Grupo de Materiais
                  </button>
                </div>
              </div>
              <div className="px-8 py-2">
                <p className="text-base font-semibold text-black mb-1">
                  Hospital
                </p>
                <TypeaheadDropdown
                  index={0}
                  value={dadosModal?.hospital as string}
                  options={hospitais.map((hospital: Hospitais) => ({ "id": hospital.id, "nome": hospital.nome }))}
                  onChange={(value: string) => handleTypeaheadDropdownChange("hospital", value, 0)}
                  required={true}
                  disabled={hospitalCheck || (dadosModal?.status.descricao !== "Pendentes" && combinedAcess)}
                />
              </div>
              <div className="px-8 py-2">
                <p className="text-base font-semibold text-black mb-1">
                  Materiais Hospitalares
                </p>
                <textarea
                  name="materiais_hospitalares"
                  className="input-modal text-sm w-full mb-2 border-solid border-b-2 border-gray-400"
                  value={dadosModal?.materiais_hospitalares}
                  onChange={handleTextAreaChange}
                  disabled={hospitalCheck || (dadosModal?.status.descricao !== "Pendentes" && combinedAcess)}
                />
              </div>
              <div className="px-8 py-2">
                <p className="text-base font-semibold text-black mb-1">
                  Vencimento da Guia
                </p>
                <InputMask
                  mask="99/99/9999"
                  onChange={handleInputChange}
                  disabled={hospitalCheck || (dadosModal?.status.descricao !== "Pendentes" && dadosModal?.status.descricao !== "Autorizada")}
                  value={dadosModal?.vencimento?.split("-").reverse().join("/")}
                >
                  <input
                    name="vencimento"
                    type="text"
                    className="input-modal text-sm w-full mb-2 border-solid border-b-2 border-gray-400"
                    required={true}
                  />
                </InputMask>
                {!isDateValid && <p style={{ color: 'red' }}>Data de vencimento deve ser maior ou igual a data de hoje</p>}
              </div>
              <div className="flex justify-center">
                <button
                  className={`btn-new-vendedor text-xs font-bold rounded-full content-center px-2 py-1 flex mt-1 shadow-md ${(dadosModal?.status?.id === 1 || dadosModal?.status?.id === 2 || dadosModal?.status?.id === 9) && !hospitalCheck && isDateValid ? 'bg-blue text-white' : 'bg-gray-400 text-gray-600'}`}
                  disabled={(dadosModal?.status?.id === 1 || dadosModal?.status?.id === 2 || dadosModal?.status?.id === 9) && !hospitalCheck && isDateValid ? false : true}
                >
                  {dadosModal?.status?.id === 1 ? "Salvar" : "Editar"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default connector(Accordion);