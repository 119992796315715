import "./style.css";
import React, { useEffect, useState } from "react";
import Img from "src/components/Img Element";
import Loader from "src/components/Loader";
import "react-toastify/dist/ReactToastify.css";
import imgIntro from "src/assets/img-intro.png";
import logoIntro from '../../../assets/logo-intro.png';
// import bgIntro from "src/assets/bg-intro-mobile.png";
import bgIntroTablet from '../../../assets/Tablet/Intro/Bg-Intro.png';
import { useLocation } from "react-router-dom";
import health from '../../../assets/Health-Icon.svg';
import heart from '../../../assets/Heart-Icon.svg';

type Props = {
  children: Array<JSX.Element> | JSX.Element;
};

export default function IntroLayout({ children }: Props): JSX.Element {
  const location = useLocation();

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoader(false), 1500);
  }, []);


  return (
    <>
      <Loader isActive={loader}>
        <div className="bg-login grid grid-cols-4 items-start h-screen w-screen fixed">
          <div className="col-span-2">
            <Img />
          </div>
          <div className="col-span-1"></div>
        </div>

        <div className={`img-main-container${location.pathname === '/solicitacao' ? ' patient' : ''}`}>
          {
            // window.innerWidth <= 414 ?
            //   <div className="bg-img-mobile-container flex justify-center align-middle bg-scroll">
            //     <img className={`${location.pathname === '/fornecedor' ?'form-type-logo-intro ' : ''}logo-intro`} alt='logo' src={logoIntro}/>
            //     <img className='bg-img-health' src={health} alt="health-icon" />
            //     <img className='bg-img-heart' src={heart} alt="heart-icon" />
            //     <img
            //       className={`bg-img-mobile xl:hidden lg:hidden md:hidden sm:visible xs:visible xl:py-0 lg:py-0 md:py-0 sm:flex img-mobile py-8`}
            //       src={bgIntro}
            //       alt="img-mobile"
            //     />
            // </div> :
            window.innerWidth <= 832 ?
              <div className="bg-img-mobile-container flex justify-center align-middle bg-scroll">
                <h1 className={`${location.pathname === '/' || location.pathname === '/quem-sou' || location.pathname === '/logout' ? 'intro-text-message absolute' : 'hidden'}`}><span className='intro-text-modifier'>Bem-vindo ao Cirurgia Autorizada!</span><br></br><br></br><br></br><br></br>
                  Antes de prosseguir, selecione seu tipo de usuário</h1>
                <img className={`${location.pathname === '/fornecedor' ? '' : ''}logo-intro`} alt='logo' src={logoIntro} />
                <img className={`bg-img-health${location.pathname === '/fornecedor' ? ' form-bg-img-health' : ''}`} src={health} alt="health-icon" />
                <img className={`bg-img-heart${location.pathname === '/fornecedor' ? ' form-bg-img-heart' : ''}`} src={heart} alt="heart-icon" />
                <img
                  className={`bg-img-mobile img-mobile py-8`}
                  src={bgIntroTablet}
                  alt="img-mobile"
                />
              </div>
              :
              <div className="bg-img-mobile-container flex justify-center align-middle bg-scroll">
                <img
                  className="xl:hidden lg:hidden md:hidden sm:visible xs:visible xl:py-0 lg:py-0 md:py-0 sm:flex img-mobile py-8"
                  src={imgIntro}
                  alt="img-mobile"
                />
              </div>
          }
          <div className={`${window.location.pathname === "/solicitacao" ? "wrapper-2" : "wrapper"} grid grid-cols-1 justify-items-center xl:justify-items-end lg:justify-items-end place-content-center intro-background`}>
            <div
              className={`${location.pathname === '/quem-sou' || location.search.toString().search('id=2') !== -1 || location.search.toString().search('id=1') !== -1 ? 'container' : ''}col-span-1 form-body w-full xl:w-1/3 lg:w-1/3 md:w-1/3 bg-white xl:m-10 lg:m-10 md:m-5 p-0 m-0
              ${location.pathname === '/' || location.pathname === '/quem-sou' || location.pathname === '/logout' ? 'intro-margin' : ''} ${location.pathname === '/solicitacao' ? 'null-height z-20' : 'z-10'}
              `}
              style={{ borderRadius: 32 }}
            >
              <div className="intro-layout-child-container p-3 w-full">{children}</div>
            </div>
          </div>
        </div>
      </Loader>
    </>
  );
}
