import React, { useEffect, useState } from "react";
// import { NavLink } from "react-router-dom";
import ModalBuscarMedico from "src/components/ModalBuscarMedico";
import { MedicosData } from ".";


interface navBar {
  indexNavBar?: number;
  setIndexNavBar: (index: number) => void;
  fetchMedicosData: () => void;
  medicosData: MedicosData;
}


const NavBarEquipeMedica: React.FC<navBar> = ({ indexNavBar, setIndexNavBar, fetchMedicosData, medicosData }) => {

  // const [buttonActive, setButtonActive] = useState(false)

  // const navButtonStyle = "btn-new-vendedor  px-3 rounded-md link-nav text-xl lg:text-md md:text-md xl:text-xl"
  // const minhaEquipeButtonStyle = `${buttonActive ? 'active' : ''} btn-new-vendedor  px-3 rounded-md link-nav text-xl lg:text-md md:text-md xl:text-xl`

  // useEffect(()=>{
  // const url = window.location.href
  // if(url.match(/equipe-medica/)) {
  //   setButtonActive(true)
  // }
  // },[])

  const [windowCheck, setWindowCheck] = useState(window.innerWidth > 833);

  useEffect(() => {
    const handleResize = () => {
      setWindowCheck(window.innerWidth > 833);
    };

    window.addEventListener('resize', handleResize);
    // Remova o listener quando o componente for desmontado
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`flex wrap items-center mobile-equipe-navigator ${!windowCheck ? '' : 'p-navigator mt-20'}`}>
      <div className="equipe-medica-nav-container">
        <button onClick={() => setIndexNavBar(0)} className={`${indexNavBar === 0 ? 'active' : null} px-3 rounded-md link-nav lg:text-md md:text-md xl:text-xl`}>Minha Equipe</button>
        <button onClick={() => setIndexNavBar(1)} className={`${indexNavBar === 1 ? 'active' : null} px-3 link-nav lg:text-md md:text-md xl:text-xl btn-new-vendedor-border-x`}>Participações</button>
        <button onClick={() => setIndexNavBar(2)} className={`${indexNavBar === 2 ? 'active' : null} px-3 rounded-md link-nav lg:text-md md:text-md xl:text-xl`}>{medicosData['solicitacoes'] === undefined ? 'Solicitações (0)' : `Solicitações (${medicosData['solicitacoes']?.length})`}</button>
      </div>
      <div className="flex">
        <ModalBuscarMedico fetchMedicosData={fetchMedicosData} />
      </div>
    </div>
  );
}

export default NavBarEquipeMedica;

