import './style.css'
import Popup from 'reactjs-popup'
import React, { useState } from 'react'
import { axiosApi } from 'src/services/axiosInstances';
import { toast } from 'react-toastify';

interface confirmacaoProps {

  id: string;
  solicitacaoId?: number;
  fetchMinhaEquipe: () => void;
  indexNavBar?: number;
}

export const pendenteStyle =
  `text-white py-1 ml-4
font-lato shadow-md
font-black rounded-full pendenteBackGroundClass minhaEquipe-btn`

export const emEquipeStyle = `bg-gray-200 text-blue-700 py-1
font-lato shadow-md ml-5
font-black rounded-full minhaEquipe-btn`



const ModalConfirmacao: React.FC<confirmacaoProps> = ({ id, solicitacaoId, fetchMinhaEquipe, indexNavBar }) => {

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);


  const handlePendentes = async (idConvite: string, status_id: number) => {
    try {
      const reqBody = { status_id: `${status_id}` };
      await axiosApi.put(`/equipe/${idConvite}`, reqBody);
      toast.success(`${status_id === 2 ? `Convite aceito com sucesso!` :
        indexNavBar === 2 ? `Convite de participação recusado!` : `Solicitação cancelada com sucesso!`}`, {
        position: "top-center",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  const confirmar = async (status_id: number) => {
    await handlePendentes(id, status_id);
    fetchMinhaEquipe();
    closeModal();
  }


  return (
    <>{indexNavBar === 2 ? (
      <div className="mobile-button-container">
        <button onClick={() => confirmar(2)} style={{ minWidth: 80 }} className="bg-blue text-white py-1 font-lato shadow-md ml-5 font-black rounded-full minhaEquipe-btn solicitacao-buttons">Aceitar</button>
        <button onClick={() => confirmar(3)} style={{ minWidth: 80 }} className="bg-gray-200 text-blue-700 py-1 font-lato shadow-md ml-5 font-black rounded-full minhaEquipe-btn solicitacao-buttons">Recusar</button>
      </div> ) :
      solicitacaoId === 1 ?
        <button onClick={openModal} style={{ width: 110 }} className={pendenteStyle}>Pendente X</button> :
        <button onClick={openModal} style={{ width: 110 }} className={emEquipeStyle}>{`Em equipe    X`}</button>}
      <Popup open={isOpen} onClose={closeModal} position="right center">
        <div className="modalConfirmacao flex flex-col border items-center text-center rounded-3xl bg-white">
          <h2 className="text-xl font-lato font-black text-center info-text-modal">
            {
              solicitacaoId === 1 ? <div><p>Deseja cancelar o convite enviado ao usuário ?</p></div> : solicitacaoId === 2 && indexNavBar === 1 ?
                `Deseja deixar de participar desta equipe médica? Você deixará de ter acesso ao calendario deste usuário.` : `Confirma desassociação do membro da equipe médica? O usuário deixará de ter acesso ao seu calendario.`
            }
          </h2>
          <div className="flex justify-evenly pr-5 pt-3">
            <button onClick={() => closeModal()} className="p-y-4 ml-5
          bg-gray-200 shadow-md px-2 h-6 font-lato rounded-full text-center font-black minhaEquipe-btn min-button-width">Cancelar</button>
            <button onClick={() => confirmar(4)} className="shadow-md backgroundClass p-y-4 ml-5 hover:bg-red-700 hover:text-white text-white px-2 h-6
            font-lato rounded-full text-center font-black minhaEquipe-btn">Confirmar</button>
          </div>
        </div>
      </Popup>
    </>
  )
}


export default ModalConfirmacao;