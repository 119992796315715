import React, { Fragment, useEffect } from "react";
import Popup from "reactjs-popup";
import AccordionEspecificacoes from "../AccordionEspecificacoes";
import AccordionAgendamento from "../AccordionAgendamento";
import AccordionDisabled from "../AccordionDisabled";
import AccordionAgendadas from "../AccordionAgendadas";
import { AiFillCloseCircle, AiOutlineFileText } from "react-icons/ai";
import "../ModalChecklist/style.css";
import { axiosApi } from "src/services/axiosInstances";
import { useState } from "react";
import { Line } from "rc-progress";
import { AuthState, ChecklistState } from "src/store/types";
import { connect, ConnectedProps } from "react-redux";
import FornecedorPost from "../FornecedorPostModal";
import { ToastContainer } from "react-toastify";
import ModalEditarAgendadas from "../ModalEditarAgendadas";
import ModalDesmarcarAgendadas from "../ModalDesmarcarAgendadas";
import { setUpdateChecklist } from "src/store/actions/checklist";
import { AccordionPosCirurgia } from "../AccordionPosCirurgia";
import Loader from "../Loader";
import Img from "src/assets/info.png";
import ModalReportado from "../ModalReportado";

const mapStateToProps = ({
  auth,
  cirurgias,
}: {
  auth: AuthState;
  cirurgias: ChecklistState;
}): AuthState & ChecklistState => {
  return {
    usuario: auth.usuario,
    cirurgias: cirurgias.cirurgias,
  };
};
const mapDispatchToProps = {
  setUpdateChecklist,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & {
  status?: string;
  idCirurgia?: number;
  open?: any;
  close?: any;
  percent?: number | number[];
  responsiveText?: string;
  updateTable?: any;
  etapa_id?: number;
  toggle?: any;
  updateData?: () => void;
  setOpen?: any;
};

type Checklist = {
  id: number;
  status: Status;
  hospital: Hospital;
  doutor: Doutor;
  nome: string;
  cirurgia: string;
  materiais: Array<Material>;
  datas: Array<Datas>;
  confirmacao_cirurgia: boolean;
  nascimento: string;
  vencimento: string;
  data_inicial: string;
  data_final: string;
  sexo: string;
  telefone: string;
  whatsapp: string;
  email: string;
  nome_contato: string;
  parentesco_contato: string;
  telefone_contato: string;
  pre_operatorio: boolean;
  alergia: string;
  confirmacao_hora: string;
  confirmacao_data: string;
  guia_internamento: Anexos;
  guia_materiais: Anexos;
  resultados_exames: Anexos | null;
  solicitacao: Solicitacao;
};

interface Solicitacao {
  arquivo: Arquivo;
}

interface Arquivo {
  url: string;
}

type Anexos = {
  id: number;
  nome: string;
  url: string;
  tipo_mime: string;
};

type Status = {
  id: number;
  descricao: string;
};

type Hospital = {
  id: number;
  nome: string;
};

type Doutor = {
  id: number;
  nome: string;
};

type Material = {
  id: number;
  checklist_id: number;
  fornecedor_id: number;
  descricao: string;
  reportar_problema: string;
  confirmado: boolean;
  fornecedor: Fornecedor;
  created_at: string;
};

type Fornecedor = {
  id: number;
  razao_social: string;
  usuario_id: number;
};

type Report = {
  id: number;
  fornecedor_id: number;
  descricao: string;
  reportar_problema: string;
};

type Datas = {
  cirurgia_id: number;
  data: string;
};

function ModalChecklist({
  idCirurgia,
  status,
  responsiveText = "xl:text-base md:text-sm sm:text-sm capitalize",
  percent,
  open,
  close,
  usuario,
  etapa_id = 3,
  updateTable,
  updateData,
  setOpen,
  cirurgias,
}: Props) {
  const [dadosModal, setDadosModal] = useState<Checklist>();
  const material = dadosModal?.materiais?.find(
    (cirurgia) => cirurgia.checklist_id === idCirurgia
  );
  const [report, setReport] = useState<Report>();
  const [loader, setLoader] = useState(true);

  const [openEditar, setOpenEditar] = useState(false);
  const toggle = () => setOpenEditar(!openEditar);

  const toggleChecklist = () => setOpen(!open);
  const closeModal = () => setOpen(false);

  const [openDesmarcar, setOpenDesmarcar] = useState(false);
  const toggleDesmarcar = () => setOpenDesmarcar(!openDesmarcar);

  const loadDados = async () => {
    try {
      setLoader(true);
      const { data } = await axiosApi.get<Checklist>(
        `/checklist/${idCirurgia}`
      );
      setDadosModal(data);
      console.log(data)
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (idCirurgia) {
      loadDados();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCirurgia, status]);

  const loadDadosReport = async () => {
    try {
      const { data } = await axiosApi.get<Report>(
        `/list-material/${idCirurgia}`
      );
      setReport(data);
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (idCirurgia) {
      loadDadosReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCirurgia]);

  const changeBar: string =
    dadosModal?.confirmacao_data && dadosModal.confirmacao_hora
      ? "66% Preenchido"
      : "50% Preenchido";

  const changeBarPercent: number =
    dadosModal?.confirmacao_data && dadosModal.confirmacao_hora ? 66 : 50;

  const preOperatorio: string =
    dadosModal?.pre_operatorio === true
      ? "Pré Operatório Solicitado"
      : "Pré Operatório Não Solicitado";

  const possuiAlergia: string =
    dadosModal?.alergia !== null ? "Possui Alergia" : "Não Possui Alergia";

  const [activeAccordion, setActiveAccordion] = useState("0");
  const toggleActiveAccordion = (id: string): void => {
    id = id === activeAccordion ? "0" : id;
    setActiveAccordion(id);
  };

  const confirm_data: any = dadosModal?.confirmacao_data
    ?.split("-")
    .reverse()
    .join("/");

  const data_nascimento: any = dadosModal?.nascimento
    ?.split("-")
    .reverse()
    .join("/");

  const data_inicial: any = dadosModal?.data_inicial
    ?.split("-")
    .reverse()
    .join("/");

  const data_final: any = dadosModal?.data_final
    ?.split("-")
    .reverse()
    .join("/");

  const data_vencimento: any = dadosModal?.vencimento
    ?.split("-")
    .reverse()
    .join("/");

  const modalWidth = {};
  const nivelCheck = usuario?.nivel === 1 || usuario?.nivel === 3 || usuario?.nivel === 6;
  const secretariaCheck = usuario?.nivel === 3;
  const doutorCheck = usuario?.nivel === 1;
  const adminCheck = usuario?.nivel === 5;
  const hospitalCheck = usuario?.nivel === 6;
  const posCirurgiaLevelCheck = usuario?.nivel === 1 || usuario?.nivel === 3;

  const dadosEditar = (): void => {
    setOpenEditar(true);
  };

  const desmarcarCirurgia = (): void => {
    setOpenDesmarcar(true);
  };

  // const findReport = dadosModal?.materiais.find(
  //   (item) => item.fornecedor_id === idCirurgia
  // );

  // console.log(findReport)

  const handleGuiaPopup = (status: any) => {

    if (status === "Solicitada") {
      window.open(dadosModal?.solicitacao?.arquivo?.url, '_blank');
    } else {
      window.open(dadosModal?.guia_internamento?.url, '_blank');
    }
  };

  const solicitada = status === "Solicitada"

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const getCreatedAtByCirurgiaId = (cirurgiaId: any) => {
    const material = dadosModal?.materiais.find(item => item.checklist_id === cirurgiaId);
    return material ? material.created_at : null;
  };


  return (
    <Fragment>
      <ModalEditarAgendadas
        idCirurgia={idCirurgia}
        openEditar={openEditar}
        toggle={toggle}
        toggleChecklist={toggleChecklist}
        paciente={dadosModal?.nome}
        dataCirurgia={confirm_data}
        dadosModal={dadosModal}
        setDadosModal={setDadosModal}
      />
      <ModalDesmarcarAgendadas
        idCirurgia={idCirurgia}
        open={openDesmarcar}
        // close={closeDesmarcaModal}
        toggle={toggleDesmarcar}
        toggleChecklist={toggleChecklist}
        paciente={dadosModal?.nome}
        updateData={updateData}
      />
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
      />
      <Popup
        open={open}
        closeOnDocumentClick={false}
        onClose={closeModal}
        contentStyle={modalWidth}
        modal
        className="bg-black"
      >
        <div className="flex justify-center modal w-full align-middle checklist-mobile">
          <Loader isActive={loader}>
            <div
              className={`pb-12 background-${dadosModal?.status?.descricao} px-6 get-modal`}
              style={{ width: "22rem" }}
            >
              <div className="header pt-10 pb-3">
                {solicitada && <div
                  className="rounded-full cursor-pointer close-btn"
                  onClick={toggleChecklist}
                >
                  <AiFillCloseCircle
                    size="3rem"
                    className="text-blue rounded-full bg-white"
                  />
                </div>}
                <h5
                  title={dadosModal?.status?.descricao}
                  className={`title-${dadosModal?.status?.descricao} text-md font-bold`}
                >
                  {dadosModal?.status?.descricao}
                </h5>
                {nivelCheck || adminCheck ? (
                  <h1
                    title={dadosModal?.nome}
                    className="text-black text-2xl font-bold get-nome capitalize"
                  >
                    {dadosModal?.nome}
                  </h1>
                ) : (
                  <h1
                    title={dadosModal?.doutor.nome}
                    className="text-black text-2xl font-bold get-nome capitalize"
                  >
                    {dadosModal?.doutor.nome}
                  </h1>
                )}
              </div>
              <div className="content px-5 py-10 bg-white">
                {nivelCheck || adminCheck ? (
                  <div className="n3">
                    <div
                      className={`${solicitada ? "hidden" : "pb-3"}`}
                    // className="pb-3"
                    >
                      <h6 className="text-xs text-black font-bold leading-3">
                        Data de Nascimento
                      </h6>
                      <div title={data_nascimento} className={`get-span ${responsiveText}`}>
                        <span>
                          {data_nascimento}
                        </span>
                      </div>
                    </div>
                    <div
                      className={`${solicitada ? "hidden" : "pb-3"}`}
                    // className="pb-3"
                    >
                      <h6 className="text-xs text-black font-bold leading-3">
                        Sexo
                      </h6>
                      <div
                        title={dadosModal?.sexo}
                        className={`get-span ${responsiveText}`}
                      >
                        <span>{dadosModal?.sexo}</span>
                      </div>
                    </div>
                    {dadosModal?.telefone === null ? (
                      ""
                    ) : (
                      <div className="pb-3">
                        <h6 className="text-xs text-black font-bold leading-3">
                          Telefone
                        </h6>
                        <div
                          title={dadosModal?.telefone}
                          className={`get-span ${responsiveText}`}
                        >
                          <span>{dadosModal?.telefone}</span>
                        </div>
                      </div>
                    )}

                    <div className="pb-3">
                      <h6 className="text-xs text-black font-bold leading-3">
                        Whatsapp
                      </h6>
                      <div
                        title={dadosModal?.whatsapp}
                        className={`get-span ${responsiveText}`}
                      >
                        <span>{dadosModal?.whatsapp}</span>
                      </div>
                    </div>
                    <div className="pb-3">
                      <h6 className="text-xs text-black font-bold leading-3">
                        Email
                      </h6>
                      <div
                        title={dadosModal?.email}
                        className={`get-span normal-case ${responsiveText}`}
                      >
                        <span>{dadosModal?.email}</span>
                      </div>
                    </div>
                    {dadosModal?.nome_contato === null &&
                      dadosModal.parentesco_contato === null &&
                      dadosModal.telefone_contato === null ? (
                      ""
                    ) : (
                      <Fragment>
                        <div className="pb-3">
                          <h6 className="text-xs text-black font-bold leading-3">
                            Nome Pessoa de Contato
                          </h6>
                          <div
                            title={dadosModal?.nome_contato}
                            className={`get-span normal-case ${responsiveText}`}
                          >
                            <span>{dadosModal?.nome_contato}</span>
                          </div>
                        </div>
                        <div className="pb-3">
                          <h6 className="text-xs text-black font-bold leading-3">
                            Grau de Parentesco
                          </h6>
                          <div
                            title={dadosModal?.parentesco_contato}
                            className={`get-span normal-case ${responsiveText}`}
                          >
                            <span>{dadosModal?.parentesco_contato}</span>
                          </div>
                        </div>
                        <div className="pb-3">
                          <h6 className="text-xs text-black font-bold leading-3">
                            Telefone do Contato
                          </h6>
                          <div
                            title={dadosModal?.telefone_contato}
                            className={`get-span normal-case ${responsiveText}`}
                          >
                            <span>{dadosModal?.telefone_contato}</span>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    <div
                      className={`${solicitada ? "hidden" : "pb-3"}`}
                    // className="pb-3"
                    >
                      <h6 className="text-xs text-black font-bold leading-3">
                        {preOperatorio}
                      </h6>
                    </div>
                    <div
                      className={`${solicitada ? "hidden" : "pb-3"}`}
                    // className="pb-3"
                    >
                      <h6 className="text-xs text-black font-bold leading-3">
                        {possuiAlergia}
                      </h6>
                      <p className="get-span text-xs break-words">
                        {dadosModal?.alergia}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="n2">
                    <div className="pb-3">
                      <h6 className="text-xs text-black font-bold leading-3">
                        Paciente
                      </h6>
                      <div
                        title={dadosModal?.nome}
                        className={`get-span ${responsiveText}`}
                      >
                        <span>{dadosModal?.nome}</span>
                      </div>
                    </div>
                    <div className="pb-3">
                      <h6 className="text-xs text-black font-bold leading-3">
                        Cirurgia
                      </h6>
                      <div
                        title={dadosModal?.cirurgia}
                        className={`get-span ${responsiveText}`}
                      >
                        <span>{dadosModal?.cirurgia}</span>
                      </div>
                    </div>
                    <div className="pb-3">
                      <h6 className="text-xs text-black font-bold leading-3">
                        Hospital
                      </h6>
                      <div
                        title={dadosModal?.hospital?.nome}
                        className={`get-span ${responsiveText}`}
                      >
                        <span>{dadosModal?.hospital?.nome}</span>
                      </div>
                    </div>
                    <div className="pb-3">
                      <h6 className="text-xs text-black font-bold leading-3">
                        Data da Cirurgia
                      </h6>
                      <div className="flex">
                        <span
                          title={`${data_inicial} - ${data_final}`}
                        >
                          {data_inicial}{" "}-{" "}{data_final}
                        </span>
                      </div>
                    </div>
                    <div className="pb-3">
                      <h6 className="text-xs text-black font-bold leading-3">
                        Vencimento da Guia
                      </h6>
                      <div title={data_vencimento} className={`get-span ${responsiveText}`}>
                        <span>
                          {data_vencimento}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <div className="pb-0 pt-2">
                  <h3 className="text-blue font-bold leading-3 pb-3">Anexos</h3>

                  {nivelCheck || adminCheck ? (

                    <div className="pb-2">
                      <h6 className="text-xs text-black font-bold leading-3">
                        {solicitada ? `Guia de Solicitação` : `Guia de Internação`}
                      </h6>
                      <div className="flex items-center">
                        <AiOutlineFileText
                          className="cursor-pointer mt-1 text-blue mr-1"
                          size="1.3rem"
                          onClick={() => handleGuiaPopup(status)}
                        />

                        <div className="text-gray-900 text-xs cursor-pointer mt-1 file-span">
                          <a
                            className="border-none outline-none hover:text-blue-500 transition duration-150 ease-in-out"
                            // eslint-disable-next-line react/jsx-no-target-blank
                            target="_blank"
                            title={dadosModal?.guia_internamento?.nome}
                            href={`${solicitada ? dadosModal?.solicitacao?.arquivo?.url : dadosModal?.guia_internamento?.url}`}
                          >
                            {solicitada ? 'solicitacao.pdf' : dadosModal?.guia_internamento?.nome}
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <Fragment
                  >
                    <div
                      // className=""
                      className={`${solicitada ? "hidden" : ""}`}
                      style={{ width: "100%" }}>
                      <span className="file-span flex">
                        <div className="text-gray-900 text-sm file-span">
                          {dadosModal?.guia_materiais === null ? (
                            <h6 className="text-xs text-grey font-bold pt-2 leading-3 cursor-default">
                              *Sem Anexo de Guia de Materiais
                            </h6>
                          ) : (
                            <div className="cursor-pointer">
                              <h6 className="text-xs text-black font-bold leading-3">
                                Guia de Materiais
                              </h6>
                              <div className="flex items-center">
                                <AiOutlineFileText
                                  className="mt-1 text-blue mr-1"
                                  size="1.3rem"
                                />
                                <div className="text-gray-900 text-xs cursor-pointer mt-1 file-span">
                                  <a
                                    className="border-none text-xs mt-1 outline-none hover:text-blue-500 transition duration-150 ease-in-out"
                                    // eslint-disable-next-line react/jsx-no-target-blank
                                    target="_blank"
                                    title={dadosModal?.guia_materiais?.nome}
                                    href={`${dadosModal?.guia_materiais?.url}`}
                                  >
                                    {dadosModal?.guia_materiais?.nome}
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </span>
                    </div>
                  </Fragment>


                  {nivelCheck || adminCheck ? (
                    <Fragment>
                      <div
                        className={`${solicitada ? "hidden" : "pb-3"}`}
                        // className=""
                        style={{ width: "100%" }}>
                        <span className="file-span flex">
                          <div className="text-gray-900 text-sm file-span">
                            {dadosModal?.resultados_exames === null ? (
                              <h6 className="text-xs text-grey font-bold pt-2 leading-3 cursor-default">
                                *Sem Anexo de Resultado de Exames
                              </h6>
                            ) : (
                              <div className="cursor-pointer">
                                <h6 className="text-xs text-black font-bold leading-3">
                                  Resultado de Exames
                                </h6>
                                <div className="flex items-center">
                                  <AiOutlineFileText
                                    className="mt-1 text-blue mr-1"
                                    size="1.3rem"
                                  />
                                  <div className="text-gray-900 text-xs cursor-pointer mt-1 file-span">
                                    <a
                                      className="border-none text-xs mt-1 outline-none hover:text-blue-500 transition duration-150 ease-in-out"
                                      // eslint-disable-next-line react/jsx-no-target-blank
                                      target="_blank"
                                      title={dadosModal?.resultados_exames?.nome}
                                      href={`${dadosModal?.resultados_exames?.url}`}
                                    >
                                      {dadosModal?.resultados_exames?.nome}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </span>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div
              className={`${solicitada ? "hidden" : ""} checklist-container`}
              style={{ width: "23rem" }}>
              <div className="bg-white px-10 pb-10 post-modal shadow-lg">
                {/* {adminCheck ? (
                  <Fragment>
                    <div className="content pt-5">
                      {status === "Pendentes" && (
                        <div>
                          <h3 className="percent-info flex justify-end pt-2 text-xs font-semibold">
                            0% Preenchido
                          </h3>
                          <div className="mb-3">
                            <AccordionDisabled accordionTitle="Especificações Técnicas" />
                          </div>
                          <div className="mb-3">
                            <AccordionDisabled accordionTitle="Agendamento Final" />
                          </div>
                        </div>
                      )}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment> */}
                {nivelCheck || adminCheck ? (
                  <div className="content pt-5">
                    {(dadosModal?.status?.descricao === "Autorizada") && (
                      <Fragment>
                        <div>
                          <h3 className="percent-info flex justify-end pt-2 text-xs font-semibold">
                            0% Preenchido
                          </h3>
                          <Line
                            className="pt-0 pb-5"
                            percent={0}
                            strokeWidth={3}
                            strokeColor="var(--primary-blue-default)"
                            trailWidth={3}
                          />
                        </div>
                        {doutorCheck || secretariaCheck ? (
                          <>
                            <div className="mb-3">
                              <AccordionEspecificacoes
                                key={dadosModal?.id}
                                idCirurgia={idCirurgia}
                                updateTable={loadDados}
                                updateData={updateData}
                                accordionTitle="Especificações Técnicas"
                                actAccordion={activeAccordion === "1"}
                                toggleAccordion={() => toggleActiveAccordion("1")}
                                setLoader={setLoader}
                              />
                            </div>
                            <div className="mb-3">
                              <AccordionDisabled accordionTitle="Agendamento Final" />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="mb-3">
                              <AccordionDisabled accordionTitle="Especificações Técnicas" />
                            </div>
                            <div className="mb-3">
                              <AccordionDisabled accordionTitle="Agendamento Final" />
                            </div>
                          </>
                        )}
                      </Fragment>
                    )}

                    {dadosModal?.status?.descricao === "Reportado" && (
                      <>
                        <div className="my-3">
                          <div className="flex rounded-full shadow-md px-2" onClick={handleOpenModal}>
                            <img src={Img} alt="" className="w-8 h-8" />
                            <span className="font-bold text-xs text-gray-800 p-2">
                              <strong>Problema Reportado!</strong>
                            </span>
                          </div>
                          <ModalReportado open={isModalOpen} close={handleCloseModal} created_at={getCreatedAtByCirurgiaId(dadosModal?.id)} />
                        </div>
                        <div className="mb-3">
                          <AccordionEspecificacoes
                            key={dadosModal?.id}
                            idCirurgia={idCirurgia}
                            updateTable={loadDados}
                            updateData={updateData}
                            accordionTitle="Especificações Técnicas"
                            actAccordion={activeAccordion === "1"}
                            toggleAccordion={() => toggleActiveAccordion("1")}
                            setLoader={setLoader}
                          />
                        </div>
                        <div className="mb-3">
                          <AccordionDisabled accordionTitle="Agendamento Final" />
                        </div>
                      </>
                      // <div>
                      //   <p className="text-blue text-xl font-bold pb-3">
                      //     Material Reportado
                      //   </p>
                      //   <div className="font-bold pb-2">
                      //     {findReport?.descricao}
                      //   </div>
                      //   <div>{findReport?.reportar_problema}</div>
                      // </div>
                    )}

                    {
                      status === 'Concluída' &&
                      <div className='post-modal-completed-container'>
                        {/* <h3 className='post-modal-completed-title'>Pós - Cirurgia</h3> */}
                        <h3 className='post-modal-completed-title'>Cirurgia Concluída</h3>
                        <div>
                          <h3 className="percent-info flex justify-end pt-2 text-xs font-semibold">
                            100%
                          </h3>
                          <Line
                            className="pt-0 pb-5"
                            percent={100}
                            strokeWidth={3}
                            strokeColor="var(--primary-blue-default)"
                            trailWidth={3}
                          />
                        </div>

                        <div className="mb-3">
                          <AccordionEspecificacoes
                            key={dadosModal?.id}
                            idCirurgia={idCirurgia}
                            updateTable={loadDados}
                            updateData={updateData}
                            accordionTitle="Especificações Técnicas"
                            actAccordion={activeAccordion === "1"}
                            toggleAccordion={() => toggleActiveAccordion("1")}
                            setLoader={setLoader}
                          />
                        </div>
                        <div>
                          <AccordionAgendadas
                            key={dadosModal?.id}
                            updateTable={loadDados}
                            accordionTitle="Agendamento Final"
                            actAccordion={activeAccordion === "2"}
                            confirmacao_data={confirm_data || ""}
                            confirmacao_hora={dadosModal?.confirmacao_hora}
                            toggleAccordion={() => toggleActiveAccordion("2")}
                          />
                        </div>
                        {posCirurgiaLevelCheck &&
                          <div>
                            <AccordionPosCirurgia
                              idCirurgia={idCirurgia}
                              toggleAccordion={() => toggleActiveAccordion('3')}
                              key={dadosModal?.id}
                              accordionTitle="Pós-Cirurgia"
                              actAccordion={activeAccordion === "3"}
                            />
                          </div>
                        }
                      </div>
                    }

                    {dadosModal?.status?.descricao === "Pendentes" && (
                      <Fragment>
                        <div>
                          <h3 className="percent-info flex justify-end pt-2 text-xs font-semibold">
                            {changeBar}
                          </h3>
                          <Line
                            className="pt-0 pb-5"
                            percent={changeBarPercent}
                            strokeWidth={3}
                            strokeColor="var(--primary-blue-default)"
                            trailWidth={3}
                          />
                        </div>

                        <div className="mb-3">
                          <AccordionEspecificacoes
                            // key={idCirurgia}
                            idCirurgia={idCirurgia}
                            accordionTitle="Especificações Técnicas"
                            updateData={updateData}
                            updateTable={loadDados}
                            actAccordion={activeAccordion === "1"}
                            toggleAccordion={() => toggleActiveAccordion("1")}
                            setLoader={setLoader}
                          />
                        </div>

                        <div>
                          <AccordionAgendamento
                            // key={idCirurgia}
                            confirmacao_data={confirm_data}
                            confirmacao_hora={dadosModal?.confirmacao_hora}
                            toggleChecklist={toggleChecklist}
                            materiais={dadosModal?.materiais}
                            usuario_id={material?.fornecedor?.usuario_id}
                            updateData={updateData}
                            idCirurgia={idCirurgia}
                            confirmacao_cirurgia={
                              dadosModal?.confirmacao_cirurgia
                            }
                            confirmacao={dadosModal?.materiais.map(
                              (confirm: Material) => {
                                return confirm.confirmado;
                              }
                            )}
                            accordionTitle="Agendamento"
                            actAccordion={activeAccordion === "2"}
                            toggleAccordion={() => toggleActiveAccordion("2")}
                            setDadosModal={setDadosModal}
                            dadosModal={dadosModal}
                          />
                        </div>
                      </Fragment>
                    )}
                    {dadosModal?.status?.descricao === "Agendadas" && (
                      <Fragment>
                        <div>
                          <h3 className="percent-info flex justify-end pt-2 text-xs font-semibold">
                            100% Preenchido
                          </h3>
                          <Line
                            className="pt-0 pb-5"
                            percent={100}
                            strokeWidth={3}
                            strokeColor="var(--primary-blue-default)"
                            trailWidth={3}
                          />
                        </div>

                        <div className="mb-3">
                          <AccordionEspecificacoes
                            key={dadosModal?.id}
                            idCirurgia={idCirurgia}
                            updateTable={loadDados}
                            updateData={updateData}
                            accordionTitle="Especificações Técnicas"
                            actAccordion={activeAccordion === "1"}
                            toggleAccordion={() => toggleActiveAccordion("1")}
                            setLoader={setLoader}
                          />
                        </div>
                        <div>
                          <AccordionAgendadas
                            key={dadosModal?.id}
                            updateTable={loadDados}
                            accordionTitle="Agendamento Final"
                            actAccordion={activeAccordion === "2"}
                            confirmacao_data={confirm_data || ""}
                            confirmacao_hora={dadosModal?.confirmacao_hora}
                            toggleAccordion={() => toggleActiveAccordion("2")}
                          />
                        </div>
                        <div>
                          <AccordionPosCirurgia
                            idCirurgia={idCirurgia}
                            toggleAccordion={() => toggleActiveAccordion('3')}
                            key={dadosModal?.id}
                            accordionTitle="Pós-Cirurgia"
                            actAccordion={activeAccordion === "3"}
                          />
                        </div>

                        {hospitalCheck ? (<Fragment></Fragment>) : (
                          <div className="flex space-x-4 justify-center pt-10">
                            <div
                              onClick={dadosEditar}
                              className="btn-agendadas text-white font-bold text-sm bg-blue px-8 py-2 cursor-pointer rounded-full hover:shadow-lg"
                            >
                              Editar
                            </div>
                            <div
                              onClick={desmarcarCirurgia}
                              className="btn-agendadas text-blue font-bold text-sm bg-white px-4 py-2 cursor-pointer rounded-full border-2 border-solid border-blue hover:shadow-lg"
                            >
                              Desmarcar
                            </div>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                ) : (
                  <div className="content pt-5">
                    {dadosModal?.status?.descricao === "Pendentes" && (
                      <FornecedorPost
                        idCirurgia={idCirurgia}
                        updateTable={updateTable}
                        updateData={updateData}
                        toggle={toggleChecklist}
                      />
                    )}
                    {dadosModal?.status?.descricao === "Agendadas" && (
                      <div>
                        <p className="text-blue text-xl font-bold pb-3">
                          Material Confirmado
                        </p>
                        <div className="font-bold pb-2">
                          {report?.descricao}
                        </div>
                        {/* <div>{report?.reportar_problema}</div> */}
                      </div>
                    )}

                    {dadosModal?.status?.descricao === "Reportado" && (
                      <div>
                        <p className="text-blue text-xl font-bold pb-3">
                          Material Reportado
                        </p>
                        <div className="font-bold pb-2">
                          {report?.descricao}
                        </div>
                        <div>{report?.reportar_problema}</div>
                      </div>
                    )}
                  </div>
                )}
                {/* </Fragment>
                )} */}
              </div>
              <div
                className="rounded-full cursor-pointer close-btn"
                onClick={toggleChecklist}
              >
                <AiFillCloseCircle
                  size="3rem"
                  className="text-blue rounded-full bg-white"
                />
              </div>
            </div>
          </Loader>
        </div>
      </Popup>
    </Fragment>
  );
}

export default connector(ModalChecklist);
